<template>
    <div class="datasection pt-5 pb-5">
        <div class="container">
            <div class=" card-list p-2 p-md-4 row">
                <div class="col-7 col-md-8 col-xl-9 line-right">
                    <h4 v-if="lang === 'ind'"><b>KARTU KREDIT</b></h4>
                    <h4 v-else-if="lang === 'eng'"><b>KREDIT CARD</b></h4>
                    <p v-if="lang === 'ind'">Menerima pembayaran melalui Kartu Kredit</p>
                    <p v-else-if="lang === 'eng'">Accept payments via Credit Cards</p>
                    <img src="../../assets/img/visacard.png" alt="">
                </div>
                <div class="col-5 col-md-4 col-xl-3 data-left">
                    <div>
                        <h5 class="text-danger"><b>3% + Rp2.500,-</b></h5>
                        <p v-if="lang === 'ind'">per transaksi</p>
                        <p v-else-if="lang === 'eng'">per transaction</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container mt-4">
            <div class=" card-list p-2 p-md-4 row">
                <div class="col-7 col-md-8 col-xl-9 line-right">
                    <h4><b>VIRTUAL ACCOUNT</b></h4>
                    <p v-if="lang === 'ind'">Menerima pembayaran melalui nomor Virtual Account</p>
                    <p v-else-if="lang === 'eng'">Accept payments through Virtual Account numbers</p>
                    <img class="imgvirtual" src="../../assets/img/bankdata.png" alt="">
                </div>
                <div class="col-5 col-md-4 col-xl-3 data-left">
                    <div>
                        <h5 class="text-danger"><b>Rp4.500,-</b></h5>
                        <p v-if="lang === 'ind'">per transaksi</p>
                        <p v-else-if="lang === 'eng'">per transaction</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container mt-4">
            <div class=" card-list p-2 p-md-4 row">
                <div class="col-7 col-md-8 col-xl-9 line-right">
                    <h4 v-if="lang ==='ind'"><b>GERAI RETAIL</b></h4>
                    <h4 v-else-if="lang === 'eng'"><b>RETAIL OUTLETS</b></h4>
                    <p v-if="lang === 'ind'">Menerima pembayaran dari Gerai Retail</p>
                    <p v-else-if="lang === 'eng'">Accept payments from Retail Outlets</p>
                    <img class="imgvirtual" src="../../assets/img/geraibeli.png" alt="">
                </div>
                <div class="col-5 col-md-4 col-xl-3 data-left">
                    <div>
                        <h5 class="text-danger"><b>Rp5.000,-</b></h5>
                        <p v-if="lang === 'ind'">per transaksi</p>
                        <p v-else-if="lang === 'eng'">per transaction</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed:{
        lang: function() {
            return this.$store.state.language;
        },
    },
}
</script>
<style scoped>
.datasection{
    background-image: url('../../assets/img/bgdata.png');
    background-repeat: repeat;
}
.line-right{
    border-right: 1px solid rgba(0, 0, 0, 0.4);
}
.card-list{
    border-radius: 25px;
    background-color: #fff;
}
.data-left{
    justify-content: center;
    align-items: center;
    display: inherit;
}
@media (max-width: 500px) {
    .card-list{
        border-radius: 10px !important;
    }
    .card-list h4{
        font-size: 0.9rem;
    }
    .card-list p{
        font-size: 0.75rem;
        margin-bottom: 0px !important;
    }
    .card-list img{
        width: 150px;
    }
    .card-list h5{
        font-size: 0.8rem;
    }
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
    .imgvirtual{
        width: 100%;
    }

}

@media (min-width: 1023px) {
    .databank figure{
       width: 400px;
    }
    .imgvirtual{
        width: 350px;
    }
    .card-list{
        width: 960px !important;
        margin: 0 auto;
    }
    
}

@media (min-width: 1439px) {
    .databank figure{
       width: 500px;
    }
}

@media (min-width: 2000px) {
}
</style>