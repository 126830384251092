<template>
  <div class="home">
    <NavbarFe/>
    <HeaderAtas/>
    <DataSection/>
    <TopFooter/>
    <FooterFe/>
  </div>
</template>

<script>
// @ is an alias to /src
import NavbarFe from '@/components/NavbarFe.vue'
import HeaderAtas from '@/components/PembayaranOnline/HeaderAtas.vue'
import DataSection from '@/components/PembayaranOnline/SectionOnline.vue'
import TopFooter from '@/components/TopFooter.vue'
import FooterFe from '@/components/FooterFe.vue'

export default {
  name: 'HomeView',
  components: {
    NavbarFe,
    HeaderAtas,
    DataSection,
    TopFooter,
    FooterFe
  },
  methods:{
    // dataset(){
    //   console.log("atas");
    //   setTimeout(() => {
    //     // untuk menghilangkan modal
    //     document.getElementById('hehe').click()
    //   }, 5000);
    // },
  }
}
</script>
