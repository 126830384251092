<template>
  <div>
    <div class="datasection mt-5 pt-3 pb-5 pt-md-5" v-if="lang === 'ind'">
    <div class="container">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/">Beranda</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Kebijakan Privasi
          </li>
        </ol>
      </nav>
    </div>
    <h3 class="text-center"><b>Kebijakan Privasi</b></h3>
    <p class="text-center text-danger"><b>(PRIVACY POLICY)</b></p>
    <div class="container p-md-4 mb-5">
      <div class="container p-2 p-md-5 card">
        <p class="text-justify">
          Kebijakan Privasi ini untuk menjelaskan bagaimana PT Baliyoni Saguna
          (ArTpay, “Kami”) secara elektronis mengumpulkan, menggunakan,
          mengungkapkan, mengirimkan, menyimpan, mengolah, menguasai,
          mentransfer, mengungkapkan, dan melindungi informasi pribadi Anda
          selaku Merchant ataupun pemegang kartu atau pengguna pembayaran
          digital (konsumen) (“Anda”). Kebijakan Privasi ini berlaku bagi
          seluruh pengguna layanan-layanan, produk-produk, jasa-jasa,
          aplikasi-aplikasi, dan situs web kami (ArTpay.com) (“Layanan”),
          kecuali diatur dengan kebijakan privasi yang terpisah.
        </p>
        <p class="text-justify">
          Mohon baca Kebijakan Privasi ini dengan seksama untuk memastikan bahwa
          Anda memahami bagaimana proses pengolahan data kami. Kecuali
          didefinisikan lain, semua istilah dengan huruf kapital yang digunakan
          dalam Kebijakan Privasi ini memiliki arti yang sama dengan yang
          tercantum dalam Syarat dan Ketentuan. Kebijakan Privasi ini mencakup
          hal-hal sebagai berikut:
        </p>
        <ol class="text-justify setdata1">
          <li>Pengumpulan Informasi Pribadi</li>
          <p>
            Kami mengumpulkan informasi yang mengidentifikasikan atau dapat
            digunakan untuk mengidentifikasi, menghubungi, atau menemukan orang
            atau perangkat yang terkait dengan informasi tersebut ("Informasi
            Pribadi"). Kami dapat mengumpulkan informasi dalam berbagai macam
            bentuk dan tujuan (termasuk tujuan yang diizinkan berdasarkan
            peraturan perundang-undangan yang berlaku)
          </p>
          <ol>
            <li class="set1">
              Pengumpulan Informasi Pribadi Merchant
              <ul class="resetul">
                <li>
                  informasi umum, seperti nama, alamat kantor, alamat e-mail,
                  dan nomor telepon Merchant
                </li>
                <li>
                  informasi tambahan, seperti nama resmi usaha atau perusahaan
                  yang didaftarkan oleh Merchant, kartu tanda penduduk (KTP)
                  dari Direksi (jika badan usaha) atau KTP pemilik (jika
                  non-badan usaha), dokumen korporasi Merchant, jenis usaha
                  Merchant, website Merchant, izin usaha Merchant, Nomor Pokok
                  Wajib Pajak (NPWP), serta data rekening bank milik Merchant
                  jika diperlukan; dan/ atau
                </li>
                <li>
                  informasi mengenai transaksi internet Merchant, termasuk namun
                  tidak terbatas pada besaran nilai transaksi yang dilakukan
                  pada situs Kami, setelah Merchant berhasil terhubung dengan
                  sistem Kami.
                </li>
              </ul>
            </li>

            <li class="set1">
              Pengumpulan Informasi Pribadi Konsumen Kami akan melakukan
              pengumpulan Informasi Pribadi Konsumen (Pemegang Kartu dan/ atau
              Pengguna Uang Elektronik) (“Konsumen”) pada saat Konsumen
              melakukan pendaftaran atau menggunakan Layanan Kami terkait dengan
              pembelian barang dan/atau jasa yang dijual/ditawarkan oleh
              Merchant. Informasi Pribadi Konsumen yang Kami kumpulkan,
              termasuk, namun tidak terbatas pada:
              <ul>
                <li>
                  informasi terkait pemesanan atau pembelian barang dan/atau
                  jasa sesuai spesifikasi dan informasi yang diperlukan dalam
                  pelaksanaan pembayaran;
                </li>
                <li>
                  jenis kartu, nama Pemegang Kartu, nomor kartu, masa berlaku
                  dan service code dari kartu yang digunakan untuk melakukan
                  pembayaran;
                </li>
                <li>
                  jenis uang elektronik, nama Pengguna Uang Elektronik dan nomor
                  telepon Pengguna Uang Elektronik; dan
                </li>
                <li>
                  informasi terkait dengan transaksi internet Konsumen, seperti:
                  billing alamat penagihan, alamat pengiriman, nomor telepon,
                  e-mail serta IP address Konsumen
                </li>
              </ul>
              <span class="text-justify">
                Kami dapat mengumpulkan informasi dalam berbagai macam bentuk
                dan tujuan (termasuk tujuan yang diizinkan berdasarkan peraturan
                perundang-undangan yang berlaku).
              </span>
            </li>
            <li class="set1">
              Pengumpulan Informasi Pribadi setiap kali Anda menggunakan
              aplikasi atau mengunjungi situs web kami:
              <ol>
                <li>
                  Setiap kali Anda menggunakan aplikasi atau mengunjungi situs
                  web Kami, Kami dapat mengumpulkan data teknis tertentu
                  sehubungan dengan penggunaan Anda seperti, alamat protokol
                  internet, aktivitas dihalaman web Kami (termasuk namun tidak
                  terbatas pada informasi halaman web yang sebelumnya atau
                  selanjutnya dilihat), durasi setiap kunjungan/sesi, identitas
                  perangkat internet atau alamat kontrol akses media, dan
                  informasi mengenai produsen, model, dan sistem operasi dari
                  perangkat yang Anda gunakan untuk mengakses aplikasi atau
                  situs web Kami.
                </li>
                <li>
                  Ketika Anda menggunakan aplikasi atau mengunjungi situs web
                  kami, informasi tertentu juga dapat dikumpulkan secara
                  otomatis dengan menggunakan cookies. Cookies adalah berkas
                  data kecil yang tersimpan pada komputer atau perangkat seluler
                  anda. Kami menggunakan cookies untuk melacak aktivitas
                  pengguna dengan tujuan untuk meningkatkan antarmuka dan
                  pengalaman pengguna. Sebagian besar perangkat seluler dan
                  peramban internet mendukung penggunaan cookies; namun Anda
                  dapat menyesuaikan pengaturan pada perangkat seluler atau
                  peramban internet anda untuk menolak beberapa jenis cookies
                  atau cookies spesifik tertentu. Perangkat seluler dan/atau
                  peramban anda juga memungkinkan Anda untuk menghapus cookies
                  apa pun yang sebelumnya telah tersimpan. Namun demikian, hal
                  itu dapat mempengaruhi fungsi-fungsi yang tersedia pada
                  aplikasi atau situs web kami.
                </li>
                <li>
                  Setiap kali Anda menggunakan Layanan melalui perangkat
                  seluler, kami akan melacak dan mengumpulkan informasi lokasi
                  geografis anda dalam waktu sebenarnya. Dalam beberapa kasus,
                  anda akan diminta atau diharuskan untuk mengaktifkan Global
                  Positioning System (GPS) pada perangkat seluler Anda untuk
                  memungkinkan kami agar dapat memberikan anda pengalaman yang
                  lebih baik dalam menggunakan Layanan (misalnya, untuk
                  memberikan informasi tentang seberapa dekat penyedia layanan
                  dengan anda). Anda dapat menonaktifkan informasi pelacakan
                  lokasi geografis pada perangkat seluler anda untuk sementara
                  waktu. Namun, hal ini dapat mempengaruhi fungsi-fungsi Layanan
                  yang tersedia.
                </li>
                <li>
                  Jika anda menggunakan dan/atau ketika pembayaran atau transfer
                  dilakukan melalui fasilitas uang elektronik dan/atau dompet
                  elektronik yang disediakan oleh kami, kami dapat mengumpulkan
                  informasi tertentu terkait dengan sumber dana yang anda
                  gunakan untuk melakukan isi ulang (termasuk rincian rekening
                  bank), rincian rekening penerima penarikan, riwayat transaksi
                  (termasuk detail penerima), rincian tagihan, faktur, dan nomor
                  telepon
                </li>
                <li>
                  Jika anda menggunakan akun virtual yang disediakan oleh kami
                  untuk menerima pembayaran menggunakan uang elektronik dan/atau
                  dompet elektronik dari pembayar, baik anda penyedia layanan
                  atau merchant, kami dapat mengumpulkan informasi tertentu yang
                  terkait dengan penggunaan anda termasuk tetapi tidak terbatas
                  pada layanan dan/atau barang yang ditransaksikan, jumlah yang
                  anda kumpulkan dari setiap transaksi, rincian akun penarikan
                  atau penyelesaian pembayaran dan riwayat penarikan atau
                  penyelesaian pembayaran
                </li>
                <li>
                  Jika anda menggunakan dan/atau pada saat suatu pembayaran
                  dilakukan melalui kartu pembayaran atau rekening yang anda
                  tambahkan di Aplikasi, kami dapat mengumpulkan informasi
                  tertentu terkait catatan transaksi, termasuk rincian penerima,
                  rincian tagihan, rincian tanda terima, dan rincian nomor
                  telepon.
                </li>
              </ol>
            </li>
            <li class="set1">Informasi yang dikumpulkan dari pihak ketiga</li>
            <span class="text-justify">
              Kami juga dapat mengumpulkan Informasi Pribadi Anda dari pihak
              ketiga (termasuk afiliasi, agen, vendor, pemasok, kontraktor,
              mitra, dan pihak lainnya yang memberikan layanan kepada Kami,
              mengumpulkan Data Pribadi dan/atau melakukan tugas atas nama Kami,
              atau dengan siapa Kami melakukan kerja sama). Dalam kasus
              tersebut, Kami hanya akan mengumpulkan Informasi Pribadi Anda
              untuk atau sehubungan dengan tujuan yang melibatkan pihak ketiga
              tersebut atau tujuan kerja sama Kami dengan pihak ketiga tersebut
              (tergantung pada situasinya). Khususnya pada saat anda
              mendaftarkan kartu pembayaran atau rekening melalui Aplikasi
              dan/atau situs web Kami, dan/atau halaman kami, dan/atau
              mengakses, menambahkan dan/atau menghubungkan kartu pembayaran
              atau rekening anda pada Aplikasi dan/atau situs web Kami, dan/atau
              halaman kami, kami dapat mengumpulkan informasi keuangan tertentu
              dan catatan finansial anda (termasuk tetapi tidak terbatas pada
              catatan transaksi, rincian dan penempatan kartu pembayaran atau
              rekening, dan/atau status dan negara kartu pembayaran atau
              rekening anda) dari penerbit kredensial pembayaran tersebut atau
              dari pihak ketiga lainnya
            </span>
          </ol>
          <li>
            Penggunaan Informasi Pribadi
            <br />
            <span
              >Kami dapat menggunakan Informasi Pribadi yang dikumpulkan untuk
              tujuan sebagai berikut maupun untuk tujuan lain yang diizinkan
              oleh peraturan perundang-undangan yang berlaku (“Tujuan”):</span
            >
            <ol>
              <li class="set2">
                Penggunaan Informasi Pribadi Merchant <br />
                <span
                  >Proses pendaftaran Merchant yang Kami lakukan sesuai dengan
                  ketentuan hukum yang berlaku serta berdasarkan peraturan yang
                  berlaku kepada Kami termasuk namun tidak terbatas pada
                  Peraturan Bank Indonesia yang berkaitan dengan kegiatan usaha
                  Kami, kebijakan dari acquirer dan kebijakan dari Penerbit Uang
                  Elektronik.</span
                ><br />
                <span
                  >Jika Anda adalah Merchant, Kami dapat menggunakan Informasi
                  Pribadi Anda:</span
                >
                <ul>
                  <li>
                    untuk menyediakan Layanan yang Anda minta termasuk melakukan
                    komunikasi dan mengirimkan informasi sehubungan dengan
                    transaksi yang dilakukan dengan menggunakan Layanan Kami;
                  </li>
                  <li>
                    untuk mengindentifikasi dan mendaftarkan anda sebagai
                    Merchant dan untuk mengadministrasikan, memverifikasi,
                    menonaktifkan, atau mengelola akun Anda;
                  </li>
                  <li>
                    untuk pelaksanaan proses validasi dan verifikasi data
                    Merchant sebagai badan usaha resmi sebelum kami mendaftarkan
                    Anda sebagai Merchant, termasuk untuk proses Know Your
                    Customer (KYC);
                  </li>
                  <li>
                    untuk memberitahu Anda mengenai segala perubahan dan
                    pembaruan pada Layanan yang Kami sediakan;
                  </li>
                  <li>
                    untuk mengolah dan menanggapi pertanyaan dan saran yang
                    diterima dari Anda atau pihak lain; untuk keperluan
                    pengembangan produk, customer service dan peningkatan mutu
                    Layanan yang Kami sediakan;
                  </li>
                  <li>
                    untuk keperluan pengembangan produk, customer service dan
                    peningkatan mutu Layanan yang Kami sediakan;
                  </li>
                  <li>
                    khusus terkait data rekening bank milik Merchant akan
                    digunakan semata-mata untuk kepentingan pembayaran tagihan
                    transaksi Merchant yang terjadi sehubungan dengan layanan
                    Kami;
                  </li>
                  <li>untuk melakukan monitor jumlah dan pola transaksi;</li>
                  <li>
                    untuk keperluan administrasi serta untuk kepentingan
                    pencegahan dan penindakan atas terjadinya penipuan (fraud
                    prevention); dan
                  </li>
                  <li>
                    untuk mengirimkan komunikasi pemasaran, survei, dan
                    informasi secara langsung atau terfokus, dan informasi
                    tentang penawaran khusus mengenai Layanan Kami dan
                    afiliasi/mitra Kami.
                  </li>
                </ul>
              </li>
              <li class="set2">
                Penggunaan Informasi Pribadi Konsumen <br />
                <span
                  >Jika Anda adalah Konsumen, Kami dapat menggunakan Informasi
                  Pribadi Anda:</span
                >
                <ul>
                  <li>
                    untuk melakukan komunikasi dan mengirimkan informasi
                    sehubungan dengan transaksi yang dilakukan dengan
                    menggunakan Layanan Kami;
                  </li>
                  <li>
                    untuk mengindentifikasi sebagai Konsumen dan untuk
                    mengadministrasikan dan/ atau memverifikasi akun Anda;
                  </li>
                  <li>
                    untuk pelaksanaan proses validasi dan verifikasi data
                    sebelum penyelesaian transaksi internet yang dilakukan oleh
                    Anda;
                  </li>
                  <li>
                    untuk kepentingan transaksi internet dalam memonitor
                    keabsahan transaksi dengan menggunakan teknik fraud
                    prevention;
                  </li>
                  <li>untuk meningkatkan mutu Layanan Kami;</li>
                  <li>
                    untuk memberitahu Anda atas transaksi atau aktivitas yang
                    terjadi dalam Aplikasi atau sistem lain yang terhubung
                    dengan Aplikasi kami;
                  </li>
                  <li>
                    untuk mengolah dan menanggapi pertanyaan dan saran yang
                    diterima dari Anda;
                  </li>
                  <li>
                    untuk memelihara, mengembangkan, menguji, meningkatkan, dan
                    mempersonalisasikan Aplikasi untuk memenuhi kebutuhan dan
                    preferensi Anda sebagai pengguna;
                  </li>
                  <li>
                    untuk memantau dan menganalisis aktivitas, perilaku, dan
                    data demografis pengguna termasuk kebiasaan dan penggunaan
                    berbagai layanan yang tersedia di Aplikasi;
                  </li>
                  <li>
                    untuk memungkinkan kami mematuhi semua kewajiban berdasarkan
                    peraturan perundang-undangan yang berlaku, (namun tidak
                    terbatas pada menanggapi permintaan, penyelidikan, atau
                    arahan per
                  </li>
                  <li>
                    melakukan pengamanan dan perlindungan terhadap Konsumen dari
                    upaya penipuan yang dilakukan oleh penipu (fraudsters)
                    dengan menggunakan teknik fraud prevention.
                  </li>
                </ul>
              </li>
              <li class="set2">
                Baik Anda merupakan Merchant ataupun Konsumen atau pihak yang
                memberikan Informasi Pribadi kepada Kami, Kami juga dapat
                menggunakan Informasi Pribadi Anda secara lebih umum untuk
                tujuan-tujuan sebagai berikut (walaupun dalam kasus tertentu
                kami akan bertindak secara wajar dan tidak menggunakan Informasi
                Pribadi anda lebih dari apa yang diperlukan untuk tujuan
                tersebut):
                <ul>
                  <li>untuk melakukan proses dan fungsi bisnis terkait;</li>
                  <li>
                    untuk memantau penggunaan aplikasi dan Layanan serta
                    mengelola, mendukung serta meningkatkan efisiensi kinerja,
                    perkembangan, pengalaman pengguna dan Layanan Kami;
                  </li>
                  <li>
                    untuk memberikan bantuan sehubungan dengan dan untuk
                    menyelesaikan kesulitan teknis atau masalah operasional
                    terhadap Layanan Kami;
                  </li>
                  <li>
                    untuk menghasilkan informasi statistik dan data analitik
                    anonim untuk tujuan pengujian, penelitian, analisis,
                    pengembangan produk, kemitraan komersial, dan kerja sama;
                  </li>
                  <li>
                    untuk mencegah, mendeteksi dan menyelidiki segala kegiatan
                    yang dilarang, ilegal, tidak sah, atau curang;
                  </li>
                  <li>
                    untuk memfasilitasi transaksi aset bisnis (yang dapat berupa
                    penggabungan, akuisisi, atau penjualan aset) yang melibatkan
                    Kami dan/atau afiliasi Kami; dan
                  </li>
                  <li>
                    untuk memungkinkan Kami mematuhi semua kewajiban berdasarkan
                    peraturan perundang-undangan yang berlaku, (namun tidak
                    terbatas pada menanggapi permintaan, penyelidikan, atau
                    arahan peraturan) dan melakukan pemeriksaan audit, uji
                    tuntas dan penyelidikan.
                  </li>
                </ul>
              </li>
            </ol>
          </li>

          <li>
            Pengungkapan Informasi Pribadi
            <br />
            <span
              >Kami dapat mengungkapkan atau membagikan Informasi Pribadi Anda
              dengan afiliasi Kami dan pihak lain untuk tujuan sebagai berikut
              ini serta untuk tujuan lain yang diizinkan oleh peraturan
              perundang-undangan yang berlaku:
            </span>
            <ol>
              <li class="set3">
                <ul>
                  <li>
                    Jika anda adalah Konsumen, untuk memungkinkan Kami
                    memberikan Layanan kepada Merchant atau memungkinkan
                    Merchant untuk melakukan atau memberikan layanan kepada
                    Anda;
                  </li>
                  <li>
                    Jika anda adalah Merchant, untuk memungkinkan Konsumen untuk
                    menerima layanan dari Anda;
                  </li>
                  <li>
                    Apabila Anda adalah Merchant, sehubungan dengan segala
                    proses verifikasi yang menurut Kami perlu sebelum Kami
                    mendaftarkan anda sebagai Merchant
                  </li>
                  <li class="text-justify">
                    Jika disyaratkan atau diotorisasikan oleh peraturan
                    perundang-undangan yang berlaku (termasuk namun tidak
                    terbatas untuk menanggapi pertanyaan dari badan atau
                    otoritas pemerintah terkait regulasi, penyelidikan atau
                    pedoman, atau mematuhi persyaratan atau ketentuan
                    pengarsipan dan pelaporan berdasarkan undang-undang), untuk
                    tujuan yang ditentukan dalam peraturan perundang-undangan
                    yang berlaku;
                  </li>
                  <li class="text-justify">
                    Sehubungan dengan, penggabungan, penjualan aset perusahaan,
                    konsolidasi atau restrukturisasi, pembiayaan atau akuisisi
                    semua atau sebagian dari bisnis Kami oleh atau ke perusahaan
                    lain, untuk keperluan transaksi tersebut (bahkan jika
                    kemudian transaksi tidak dilanjutkan);
                  </li>
                  <li class="text-justify">
                    Kepada pihak ketiga (termasuk agen, vendor, pemasok,
                    kontraktor, mitra, dan pihak lain yang memberikan layanan
                    kepada Kami atau Anda, melakukan tugas atas nama Kami, atau
                    pihak dengan siapa Kami mengadakan kerja sama komersial),
                    untuk atau sehubungan dengan tujuan di mana pihak ketiga
                    tersebut terlibat atau tujuan kerja sama kami dengan pihak
                    ketiga tersebut (tergantung keadaannya), yang dapat mencakup
                    diperbolehkannya pihak ketiga tersebut untuk memperkenalkan
                    atau menawarkan produk atau layanan kepada Anda, atau
                    melakukan kegiatan lain termasuk pemasaran, penelitian,
                    analisis dan pengembangan produk; dan
                  </li>
                  <li class="text-justify">
                    dalam hal Kami berbagi Informasi Pribadi dengan afiliasi,
                    Kami akan melakukannya dengan maksud agar mereka membantu
                    Kami dalam menyediakan Layanan, untuk mengoperasikan bisnis
                    Kami (termasuk, ketika Anda berlangganan milis Kami, untuk
                    tujuan pemasaran langsung), atau untuk tujuan pengolahan
                    data atas nama Kami. Misalnya, sebuah afiliasi Kami di
                    negara lain dapat mengolah dan/atau menyimpan Informasi
                    Pribadi anda atas nama perusahaan Kami maupun grup Kami di
                    negara Anda. Semua afiliasi Kami berkomitmen untuk mengolah
                    Informasi Pribadi yang mereka terima dari kami sesuai dengan
                    Kebijakan Privasi dan peraturan perundang-undangan yang
                    berlaku
                  </li>
                </ul>
              </li>
              <li class="set3 text-justify">
                Kami tidak akan menyebarluaskan dan/atau menjual Informasi
                Pribadi Anda kepada pihak lain atau perusahaan-perusahaan yang
                tidak terafiliasi dengan Kami, kecuali untuk pelaksanaan
                penyediaan produk dan jasa layanan yang Anda minta.
              </li>
              <li class="set3 text-justify">
                Ketika Informasi Pribadi tidak berkaitan dengan Anda, kami akan
                melakukan upaya yang wajar untuk menghapus dikaitkannya
                Informasi Pribadi tersebut dengan Anda sebagai individu sebelum
                mengungkapkan atau berbagi informasi tersebut.
              </li>
              <li class="set3 text-justify">
                Selain sebagaimana diatur dalam Kebijakan Privasi ini, Kami
                dapat mengungkapkan dan membagikan Informasi Pribadi Anda jika
                Kami memberitahu Anda dan Kami telah memperoleh persetujuan Anda
                untuk pengungkapan atau pembagian tersebut.
              </li>
            </ol>
          </li>
          <li>
            Penyimpanan Informasi Pribadi <br />
            <ol>
              <li class="set4 text-justify">
                Informasi Pribadi Anda hanya akan disimpan selama diperlukan
                untuk memenuhi tujuan dari pengumpulannya, atau selama
                penyimpanan tersebut diperlukan atau diperbolehkan oleh
                peraturan perundang-undangan yang berlaku. Kami akan berhenti
                menyimpan Informasi Pribadi, atau menghapus maksud dari
                dikaitkannya Informasi Pribadi tersebut dengan Anda sebagai
                Merchant atau Konsumen, segera setelah dianggap bahwa tujuan
                pengumpulan Informasi Pribadi tersebut tidak lagi dibutuhkan
                dengan menyimpan Informasi Pribadi dan penyimpanan tidak lagi
                diperlukan untuk tujuan bisnis atau secara hukum.
              </li>
              <li class="set4 text-justify">
                Mohon diperhatikan bahwa masih ada kemungkinan bahwa beberapa
                Informasi Pribadi Anda disimpan oleh pihak lain dengan termasuk
                institusi pemerintah cara tertentu. Dalam hal kami membagikan
                Informasi Pribadi Anda kepada institusi pemerintah yang
                berwenang dan/atau institusi lainnya yang dapat ditunjuk oleh
                pemerintah yang berwenang atau memiliki kerjasama dengan kami,
                Anda menyetujui dan mengakui bahwa penyimpanan Informasi Pribadi
                Anda oleh institusi terkait akan mengikuti kebijakan penyimpanan
                data masing-masing institusi tersebut.
              </li>
            </ol>
          </li>
          <li>
            Kami tunduk pada aturan yang berlaku <br />
            <ol>
              <li class="set5 text-justify">
                Tunduk pada peraturan perundang-undangan yang berlaku, Anda
                dapat meminta Kami untuk mengakses, mengoreksi dan memperbaharui
                Informasi Pribadi Anda yang berada dalam penguasaan Kami, dengan
                menghubungi Kami di perincian yang disediakan di Poin 13 dari
                Kebijakan Privasi ini.
              </li>
              <li class="set5 text-justify">
                Kami berhak menolak permintaan Anda untuk memperbaiki, sebagian
                atau semua Informasi Pribadi Anda yang Kami miliki atau kuasai
                jika diizinkan atau diperlukan berdasarkan perundang-undangan
                yang berlaku. Hal ini termasuk dalam keadaan di mana Informasi
                Pribadi tersebut dapat berisi referensi kepada orang lain atau
                di mana permintaan untuk akses atau permintaan untuk mengoreksi
                adalah untuk alasan yang kami anggap tidak relevan, tidak
                serius, atau menyulitkan. Sebaliknya, Kami dapat menghapus
                Informasi Pribadi sesuai kebijaksanaan Kami, sehingga Anda harus
                melakukan pencatatan sendiri, dan tidak mengandalkan penyimpanan
                Informasi Pribadi atau data lainnya oleh Kami.
              </li>
            </ol>
          </li>
          <li>
            Keamanan dan Perlindungan Informasi Pribadi <br />
            <span class="text-justify"
              >Kerahasiaan Informasi Pribadi Anda adalah hal yang terpenting
              bagi Kami. Kami akan senantiasa memberikan upaya keamanan yang
              wajar dan maksimal untuk melindungi dan mengamankan seluruh
              Informasi Pribadi Anda yang Kami kumpulkan secara online baik
              melalui aplikasi dan situs Kami, dari akses, pengumpulan,
              penggunaan atau pengungkapan oleh orang-orang yang tidak berwenang
              dan dari pengolahan yang bertentangan dengan hukum, kehilangan
              yang tidak disengaja, pemusnahan dan kerusakan atau risiko serupa.
              Seluruh Informasi Pribadi yang Kami kumpulkan akan dilindungi dan
              dijaga kerahasiaannya, sesuai dengan ketentuan hukum yang berlaku. </span
            ><br />
            <span class="text-justify"
              >Kami menjamin bahwa dengan ini memenuhi ketentuan standar PCI-DSS
              dan ISO 27001, yang berkaitan dengan keamanan dan data kartu,
              termasuk terhadap perlindungan, penjagaan kerahasiaan dan juga
              pemusnahan informasi. Selain itu, situs Kami telah menggunakan
              Advanced Encryption Standard (AES) 256 dan enkripsi Transport
              Layer Security (TLS) untuk perlindungan informasi Anda. Namun,
              pengiriman informasi melalui internet tidak sepenuhnya aman. Walau
              Kami akan berusaha sebaik mungkin untuk melindungi Informasi
              Pribadi Anda, Anda mengakui bahwa Kami tidak dapat menjamin
              keutuhan dan keakuratan Informasi Pribadi apa pun yang Anda
              kirimkan melalui Internet, atau menjamin bahwa Informasi Pribadi
              tersebut tidak akan dicegat, diakses, diungkapkan, diubah atau
              dihancurkan oleh pihak ketiga yang tidak berwenang, karena
              faktor-faktor di luar kendali Kami. Anda bertanggung jawab untuk
              menjaga kerahasiaan detail Akun Anda serta kode autentikasi yang
              dikirimkan kepada nomor telepon Anda sehubungan dengan transaksi
              yang Anda lakukan melalui situs Kami, dengan siapapun dan harus
              selalu menjaga dan bertanggung jawab atas keamanan perangkat yang
              Anda gunakan.</span
            >
          </li>
          <li class="text-justify">
            Perubahan terhadap Kebijakan Privasi <br />
            Kami dapat meninjau dan mengubah Kebijakan Privasi ini atas
            kebijakan Kami sendiri untuk memastikan bahwa Kebijakan Privasi ini
            konsisten dengan perkembangan Kami di masa depan, dan/atau perubahan
            persyaratan hukum atau peraturan dari waktu ke waktu. Anda setuju
            bahwa perubahan tersebut akan menggantikan Kebijakan Privasi
            sebelumnya dan berlaku surut. Jika Kami memutuskan untuk mengubah
            Kebijakan Privasi ini, Kami akan memberitahu Anda tentang perubahan
            tersebut melalui pemberitahuan umum yang dipublikasikan pada
            Aplikasi dan/atau situs web, atau sebaliknya ke alamat e-mail Anda
            yang tercantum dalam akun Anda.
          </li>
          <li class="text-justify">
            Anda setuju bahwa Anda bertanggung jawab untuk meninjau Kebijakan
            Privasi ini secara teratur untuk informasi terbaru tentang
            pengolahan data dan praktik perlindungan data Kami, dan bahwa dengan
            Anda terus menggunakan Layanan kami, berkomunikasi dengan kami, atau
            mengakses dan menggunakan Layanan setelah adanya perubahan apapun
            terhadap Kebijakan Privasi ini akan dianggap sebagai persetujuan
            Anda terhadap Kebijakan Privasi ini dan segala perubahannya.
            PENGGUNAAN ANDA ATAS LAYANAN KAMI SETELAH TANGGAL EFEKTIF PERUBAHAN
            APAPUN TERHADAP KEBIJAKAN PRIVASI INI MERUPAKAN PERSETUJUAN ANDA
            ATAS KEBIJAKAN PRIVASI INI DAN SEGALA PERUBAHANNYA
          </li>
          <li>
            <ol>
              <li class="set9 text-justify">
                Dengan mengakses situs Kami, melakukan pendaftaran, dan
                menggunakan Layanan Kami, Anda mengakui bahwa Anda telah membaca
                dan memahami Kebijakan Privasi ini dan menyetujui segala
                ketentuannya. Secara khusus, Anda setuju dan memberikan
                persetujuan kepada Kami untuk mengumpulkan, menggunakan,
                membagikan, mengungkapkan, menyimpan, mentransfer, atau mengolah
                Informasi Pribadi Anda sesuai dengan Kebijakan Privasi ini.
              </li>
              <li class="set9 text-justify">
                Dalam keadaan di mana Anda memberikan kepada Kami Informasi
                Pribadi yang berkaitan dengan individu lain (seperti Informasi
                Pribadi yang berkaitan dengan pasangan anda, anggota keluarga,
                teman, atau pihak lain), Anda menyatakan dan menjamin bahwa Anda
                telah memperoleh persetujuan dari individu tersebut untuk, dan
                dengan ini menyetujui atas nama individu tersebut untuk,
                pengumpulan, penggunaan, pengungkapan dan pengolahan Informasi
                Pribadi mereka oleh Kami.
              </li>
              <li class="set9 text-justify">
                Anda dapat menarik persetujuan Anda untuk setiap atau segala
                pengumpulan, penggunaan atau pengungkapan Informasi Pribadi Anda
                dengan memberikan pemberitahuan yang wajar kepada Kami secara
                tertulis menggunakan rincian kontak yang disebutkan dalam Poin
                13 Kebijakan Privasi ini. Tergantung pada keadaan dan sifat
                persetujuan yang Anda tarik, Anda harus memahami dan mengakui
                bahwa setelah penarikan persetujuan tersebut, Anda mungkin tidak
                lagi dapat menggunakan Layanan. Penarikan persetujuan Anda dapat
                mengakibatkan penghentian Akun Anda atau hubungan kontraktual
                Anda dengan kami, dengan semua hak dan kewajiban yang muncul
                sepenuhnya harus dipenuhi. Setelah menerima pemberitahuan untuk
                menarik persetujuan untuk pengumpulan, penggunaan atau
                pengungkapan Informasi Pribadi Anda, Kami akan menginformasikan
                Anda tentang konsekuensi yang mungkin terjadi dari penarikan
                tersebut sehingga Anda dapat memutuskan apakah Anda tetap ingin
                menarik persetujuan atau tidak.
              </li>
            </ol>
          </li>
        </ol>
        <!-- <p class="text-justify">
          Dengan mengunjungi dan/atau mendaftar Akun pada Layanan Kami, Anda
          dan/atau orang tua, wali atau pengampu Anda (jika Anda berusia dibawah
          18 (delapan belas) tahun) menerima dan menyetujui pendekatan dan
          cara-cara yang digambarkan dalam Kebijakan Privasi ini
        </p> -->
      </div>
    </div>
  </div>
  <div class="datasection mt-5 pt-3 pb-5 pt-md-5" v-else-if="lang === 'eng'">
    <div class="container">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/">Home</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Privacy Policy
          </li>
        </ol>
      </nav>
    </div>
    <h3 class="text-center"><b>Privacy Policy</b></h3>
    <!-- <p class="text-center text-danger"><b>(PRIVACY POLICY)</b></p> -->
    <div class="container p-md-4 mb-5">
      <div class="container p-2 p-md-5 card">
        <p class="text-justify">
          This Privacy Policy is intended to explain how PT Baliyoni Saguna (ArTpay,
"We") electronically collects, uses, discloses, transfers, stores, processes,
controls, and safeguards your personal information as a Merchant or as a
cardholder or digital payment user (consumer) ("You"). This Privacy Policy
applies to all users of our services, products, applications, and websites
(ArTpay.co.id) ("Services"), unless otherwise stated in a separate privacy
policy.
        </p>
        <p class="text-justify">
          Please read this Privacy Policy carefully to ensure that you understand how
we process your data. Unless defined otherwise, all capitalized terms used in
this Privacy Policy have the same meanings as set forth in the Terms and
Conditions. This Privacy Policy covers the following:

        </p>
        <ol class="text-justify setdata1">
          <li>Collection of Personal Information</li>
          <p>
            We collect information that identifies or can be used to identify, contact, or
locate individuals or devices associated with such information ("Personal
Information"). We may collect information in various forms and for various
purposes (including purposes allowed by applicable regulations).

          </p>
          <ol>
            <li class="set1">
              Collection of Merchant's Personal Information
              <ul class="resetul">
                <li>
                  General information, such as the Merchant's name, office address,
email address, and phone number.
                </li>
                <li>
                  Additional information, such as the official business name or company
registered by the Merchant, identity card (KTP) of the Directors (if a
business entity) or the owner (if non-business entity), Merchant's
corporate documents, Merchant's business type, Merchant's website,
Merchant's business license, Tax Identification Number (NPWP), and
Merchant's bank account data if necessary.
                </li>
                <li>
                  nformation about Merchant's internet transactions, including but not
limited to transaction amounts conducted on our site after the Merchant
successfully connects to our system.
                </li>
              </ul>
            </li>

            <li class="set1">
              Collection of Consumer's Personal Information We will collect Personal Information of Consumers (Cardholders and/or
E-money Users) ("Consumers") when Consumers register or use our Services
related to the purchase of goods and/or services sold/offered by the
Merchant. Personal Information of Consumers that we collect may include,
but is not limited to:
              <ul>
                <li>
                  Information related to ordering or purchasing goods and/or services
according to specifications and information required for payment
processing.
                </li>
                <li>
                  Card type, Cardholder's name, card number, expiration date, and
service code of the card used for payment.
                </li>
                <li>
                  E-money type, E-money User's name, and E-money User's phone
number.
                </li>
                <li>
                  Information related to Consumer's internet transactions, such as billing
address, shipping address, phone number, email, and Consumer's IP
address.
                </li>
              </ul>
              <span class="text-justify">
                KWe may collect information in various forms and for various purposes
(including purposes allowed by applicable regulations).
              </span>
            </li>
            <li class="set1">
              Collection of Personal Information Every Time You Use Our Application
or Visit Our Website:
              <ol>
                <li>
                  Every time you use our application or visit our website, we may collect certain
technical data related to your usage, such as your internet protocol address,
activity on our web pages (including but not limited to previously or
subsequently viewed web page information), duration of each visit/session,
internet device identity or media access control address, and information
about the manufacturer, model, and operating system of the device you use to
access our application or website.
                </li>
                <li>
                  When you use our application or visit our website, certain information may
also be automatically collected using cookies. Cookies are small data files
stored on your computer or mobile device. We use cookies to track user
activity to improve the user interface and user experience. Most mobile
devices and web browsers support the use of cookies; however, you can
adjust settings on your mobile device or web browser to reject some types of
cookies or specific cookies. Your mobile device and/or browser also allow you
to delete any previously stored cookies. However, this may affect the
functionalities available on our application or website.
                </li>
                <li>
                  Every time you use our Services through a mobile device, we will track and
collect your real-time geographic location information. In some cases, you will
be asked or required to enable the Global Positioning System (GPS) on your
mobile device to allow us to provide you with a better experience when using
our Services (e.g., providing information about service providers near you).
You can temporarily disable geographic location tracking on your mobile
device. However, this may affect the available functions of the Services.
                </li>
                <li>
                  If you use and/or when payments or transfers are made through the electronic
money and/or electronic wallet facilities provided by us, we may collect
specific information related to the source of funds you use for top-up
(including bank account details), recipient account details for withdrawals,
transaction history (including recipient details), billing details, invoices, and
phone numbers.
                </li>
                <li>
                  If you use a virtual account provided by us to receive payments using
electronic money and/or electronic wallets from payers, whether you are a
service provider or a merchant, we may collect specific information related to
your usage, including but not limited to services and/or goods transacted, the
amount collected from each transaction, account details for withdrawals or
payment settlements, and withdrawal or payment settlement history.
                </li>
                <li>
                  If you use and/or when a payment is made through a payment card or bank
account added to the Application, we may collect specific information related
to transaction records, including recipient details, billing details, receipt
details, and phone number.
                </li>
              </ol>
            </li>
            <li class="set1">Information Collected from Third Parties:</li>
            <span class="text-justify">
              We may also collect your Personal Information from third parties (including
affiliates, agents, vendors, suppliers, contractors, partners, and others who
provide services to us, collect Personal Data and/or perform tasks on our
behalf, or with whom we cooperate). In such cases, we will only collect your
Personal Information for or in connection with the purposes involving such
third parties or our cooperation with such third parties (depending on the
circumstances). In particular, when you register a payment card or account
through our Application and/or website, and/or our pages, and/or access, add,
and/or link your payment card or account on our Application and/or website,
and/or our pages, we may collect certain financial information and financial
records of yours (including but not limited to transaction records, card or
account placement details, and/or card or account status and country) from
the payment credential issuer or other third parties.
            </span>
          </ol>
          <li>
            Use of Personal Information
            <br />
            <span
              >We may use the Personal Information collected for the following purposes,
among others, and for other purposes permitted by applicable laws and
regulations ("Purposes"):</span
            >
            <ol>
              <li class="set2">
                Use of Merchant's Personal Information <br />
                <span
                  >To process Merchant registration in accordance with applicable laws
and based on the regulations that apply to us, including but not limited
to Bank Indonesia Regulations related to our business activities,
policies from acquirers, and policies from Electronic Money Issuers.</span
                ><br />
                <span
                  >If you are a Merchant, we may use your Personal Information:</span
                >
                <ul>
                  <li>
                    To provide the Services you request, including communication
and sending information related to transactions conducted using
our Services.
                  </li>
                  <li>
                    To identify and register you as a Merchant and to administer,
verify, disable, or manage your account.
                  </li>
                  <li>
                    For the validation and verification process of Merchant data as
an official business entity before we register you as a Merchant,
including for Know Your Customer (KYC) purposes.
                  </li>
                  <li>
                    To notify you of any changes and updates to the Services and
these Terms and Conditions.
                  </li>
                  <li>
                    For statistical analysis, marketing, research, and analytical
purposes to improve our Services, and to understand trends and
usage patterns.
                  </li>
                  <li>
                    For other purposes that you may consent to from time to time.
                  </li>
                  <!-- <li>
                    khusus terkait data rekening bank milik Merchant akan
                    digunakan semata-mata untuk kepentingan pembayaran tagihan
                    transaksi Merchant yang terjadi sehubungan dengan layanan
                    Kami;
                  </li>
                  <li>untuk melakukan monitor jumlah dan pola transaksi;</li>
                  <li>
                    untuk keperluan administrasi serta untuk kepentingan
                    pencegahan dan penindakan atas terjadinya penipuan (fraud
                    prevention); dan
                  </li>
                  <li>
                    untuk mengirimkan komunikasi pemasaran, survei, dan
                    informasi secara langsung atau terfokus, dan informasi
                    tentang penawaran khusus mengenai Layanan Kami dan
                    afiliasi/mitra Kami.
                  </li> -->
                </ul>
              </li>
              <li class="set2">
                Use of Consumer's Personal Information <br />
                <span
                  >If you are a Consumer, we may use your Personal Information:</span
                >
                <ul>
                  <li>
                    To provide the Services you request, including communication
and sending information related to transactions conducted using
our Services.
                  </li>
                  <li>
                    To identify and register you as a Consumer and to administer,
verify, disable, or manage your account.
                  </li>
                  <li>
                    For the validation and verification process of Consumer data
before we register you as a Consumer, including for Know Your
Customer (KYC) purposes.
                  </li>
                  <li>
                    To notify you of any changes and updates to the Services and
these Terms and Conditions.
                  </li>
                  <li>For statistical analysis, marketing, research, and analytical
purposes to improve our Services and to understand trends and
usage patterns.
</li>
                  <li>
                    For other purposes that you may consent to from time to time
                  </li>
                  <!-- <li>
                    untuk mengolah dan menanggapi pertanyaan dan saran yang
                    diterima dari Anda;
                  </li>
                  <li>
                    untuk memelihara, mengembangkan, menguji, meningkatkan, dan
                    mempersonalisasikan Aplikasi untuk memenuhi kebutuhan dan
                    preferensi Anda sebagai pengguna;
                  </li>
                  <li>
                    untuk memantau dan menganalisis aktivitas, perilaku, dan
                    data demografis pengguna termasuk kebiasaan dan penggunaan
                    berbagai layanan yang tersedia di Aplikasi;
                  </li>
                  <li>
                    untuk memungkinkan kami mematuhi semua kewajiban berdasarkan
                    peraturan perundang-undangan yang berlaku, (namun tidak
                    terbatas pada menanggapi permintaan, penyelidikan, atau
                    arahan per
                  </li>
                  <li>
                    melakukan pengamanan dan perlindungan terhadap Konsumen dari
                    upaya penipuan yang dilakukan oleh penipu (fraudsters)
                    dengan menggunakan teknik fraud prevention.
                  </li> -->
                </ul>
              </li>
              <!-- <li class="set2">
                Baik Anda merupakan Merchant ataupun Konsumen atau pihak yang
                memberikan Informasi Pribadi kepada Kami, Kami juga dapat
                menggunakan Informasi Pribadi Anda secara lebih umum untuk
                tujuan-tujuan sebagai berikut (walaupun dalam kasus tertentu
                kami akan bertindak secara wajar dan tidak menggunakan Informasi
                Pribadi anda lebih dari apa yang diperlukan untuk tujuan
                tersebut):
                <ul>
                  <li>untuk melakukan proses dan fungsi bisnis terkait;</li>
                  <li>
                    untuk memantau penggunaan aplikasi dan Layanan serta
                    mengelola, mendukung serta meningkatkan efisiensi kinerja,
                    perkembangan, pengalaman pengguna dan Layanan Kami;
                  </li>
                  <li>
                    untuk memberikan bantuan sehubungan dengan dan untuk
                    menyelesaikan kesulitan teknis atau masalah operasional
                    terhadap Layanan Kami;
                  </li>
                  <li>
                    untuk menghasilkan informasi statistik dan data analitik
                    anonim untuk tujuan pengujian, penelitian, analisis,
                    pengembangan produk, kemitraan komersial, dan kerja sama;
                  </li>
                  <li>
                    untuk mencegah, mendeteksi dan menyelidiki segala kegiatan
                    yang dilarang, ilegal, tidak sah, atau curang;
                  </li>
                  <li>
                    untuk memfasilitasi transaksi aset bisnis (yang dapat berupa
                    penggabungan, akuisisi, atau penjualan aset) yang melibatkan
                    Kami dan/atau afiliasi Kami; dan
                  </li>
                  <li>
                    untuk memungkinkan Kami mematuhi semua kewajiban berdasarkan
                    peraturan perundang-undangan yang berlaku, (namun tidak
                    terbatas pada menanggapi permintaan, penyelidikan, atau
                    arahan peraturan) dan melakukan pemeriksaan audit, uji
                    tuntas dan penyelidikan.
                  </li>
                </ul>
              </li> -->
            </ol>
          </li>

          <li>
            Sharing of Personal Information
            <br />
            <span
              >We do not sell, lease, or trade your Personal Information to third parties
without your consent, except as described below:
            </span>
            <ol>
              <!-- <li class="set3">
                <ul>
                  <li>
                    Jika anda adalah Konsumen, untuk memungkinkan Kami
                    memberikan Layanan kepada Merchant atau memungkinkan
                    Merchant untuk melakukan atau memberikan layanan kepada
                    Anda;
                  </li>
                  <li>
                    Jika anda adalah Merchant, untuk memungkinkan Konsumen untuk
                    menerima layanan dari Anda;
                  </li>
                  <li>
                    Apabila Anda adalah Merchant, sehubungan dengan segala
                    proses verifikasi yang menurut Kami perlu sebelum Kami
                    mendaftarkan anda sebagai Merchant
                  </li>
                  <li class="text-justify">
                    Jika disyaratkan atau diotorisasikan oleh peraturan
                    perundang-undangan yang berlaku (termasuk namun tidak
                    terbatas untuk menanggapi pertanyaan dari badan atau
                    otoritas pemerintah terkait regulasi, penyelidikan atau
                    pedoman, atau mematuhi persyaratan atau ketentuan
                    pengarsipan dan pelaporan berdasarkan undang-undang), untuk
                    tujuan yang ditentukan dalam peraturan perundang-undangan
                    yang berlaku;
                  </li>
                  <li class="text-justify">
                    Sehubungan dengan, penggabungan, penjualan aset perusahaan,
                    konsolidasi atau restrukturisasi, pembiayaan atau akuisisi
                    semua atau sebagian dari bisnis Kami oleh atau ke perusahaan
                    lain, untuk keperluan transaksi tersebut (bahkan jika
                    kemudian transaksi tidak dilanjutkan);
                  </li>
                  <li class="text-justify">
                    Kepada pihak ketiga (termasuk agen, vendor, pemasok,
                    kontraktor, mitra, dan pihak lain yang memberikan layanan
                    kepada Kami atau Anda, melakukan tugas atas nama Kami, atau
                    pihak dengan siapa Kami mengadakan kerja sama komersial),
                    untuk atau sehubungan dengan tujuan di mana pihak ketiga
                    tersebut terlibat atau tujuan kerja sama kami dengan pihak
                    ketiga tersebut (tergantung keadaannya), yang dapat mencakup
                    diperbolehkannya pihak ketiga tersebut untuk memperkenalkan
                    atau menawarkan produk atau layanan kepada Anda, atau
                    melakukan kegiatan lain termasuk pemasaran, penelitian,
                    analisis dan pengembangan produk; dan
                  </li>
                  <li class="text-justify">
                    dalam hal Kami berbagi Informasi Pribadi dengan afiliasi,
                    Kami akan melakukannya dengan maksud agar mereka membantu
                    Kami dalam menyediakan Layanan, untuk mengoperasikan bisnis
                    Kami (termasuk, ketika Anda berlangganan milis Kami, untuk
                    tujuan pemasaran langsung), atau untuk tujuan pengolahan
                    data atas nama Kami. Misalnya, sebuah afiliasi Kami di
                    negara lain dapat mengolah dan/atau menyimpan Informasi
                    Pribadi anda atas nama perusahaan Kami maupun grup Kami di
                    negara Anda. Semua afiliasi Kami berkomitmen untuk mengolah
                    Informasi Pribadi yang mereka terima dari kami sesuai dengan
                    Kebijakan Privasi dan peraturan perundang-undangan yang
                    berlaku
                  </li>
                </ul>
              </li> -->
              <!-- <li class="set3 text-justify">
                Kami tidak akan menyebarluaskan dan/atau menjual Informasi
                Pribadi Anda kepada pihak lain atau perusahaan-perusahaan yang
                tidak terafiliasi dengan Kami, kecuali untuk pelaksanaan
                penyediaan produk dan jasa layanan yang Anda minta.
              </li>
              <li class="set3 text-justify">
                Ketika Informasi Pribadi tidak berkaitan dengan Anda, kami akan
                melakukan upaya yang wajar untuk menghapus dikaitkannya
                Informasi Pribadi tersebut dengan Anda sebagai individu sebelum
                mengungkapkan atau berbagi informasi tersebut.
              </li>
              <li class="set3 text-justify">
                Selain sebagaimana diatur dalam Kebijakan Privasi ini, Kami
                dapat mengungkapkan dan membagikan Informasi Pribadi Anda jika
                Kami memberitahu Anda dan Kami telah memperoleh persetujuan Anda
                untuk pengungkapan atau pembagian tersebut.
              </li> -->
            </ol>
          </li>
          <li>
            Sharing with Other Users <br>
            If you are a Merchant, we may share your Personal Information with
Consumers who transact with you using our Services. This may include
sharing your name, business name, contact information, and transaction
details.
          </li>
          <li>
            Sharing with Service Providers <br>
            We may share your Personal Information with third-party service providers
who perform services on our behalf, such as payment processing, data
analysis, email delivery, hosting services, customer service, and marketing
assistance. These service providers have access to your Personal Information
only as necessary to perform their functions and are contractually obligated to
maintain the confidentiality and security of your Personal Information.

          </li>
          <li>
            Sharing with Business Partners <br>
            We may share your Personal Information with business partners, including
financial institutions, payment card networks, and other organizations with
which we collaborate to offer our Services.
          </li>
          <li>
            Sharing with Legal Authorities <br>
            We may disclose your Personal Information to comply with applicable laws,
            regulations, legal processes, or government requests. We may also disclose
            your Personal Information to enforce our Terms and Conditions or to protect
            our rights, privacy, safety, or property, or the rights, privacy, safety, or property
            of others.
          </li>
          <li>
            Sharing in Connection with a Business Transaction <br>
            In the event that we are involved in a merger, acquisition, asset sale,
            bankruptcy, or other business transaction, your Personal Information may be
            transferred or disclosed as part of that transaction.
          </li>
          <li>
            Data Security <br>
            We take reasonable and appropriate measures to protect your Personal
            Information from loss, misuse, unauthorized access, disclosure, alteration,
            and destruction. These measures include technical, administrative, and
            physical safeguards, such as firewalls, encryption, access controls, and
            employee training.
          </li>
          <li>
            Retention of Personal Information <br>
            We will retain your Personal Information for as long as necessary to fulfill the
            purposes for which it was collected or as required by applicable laws and
            regulations. When we no longer need your Personal Information, we will
            securely delete or anonymize it.
          </li>
          <li>
            Your Rights <br>
            You have certain rights regarding your Personal Information, including the
            right to:
            <ol>
              <li>
                Access your Personal Information and request a copy of it.
              </li>
              <li>
                Correct any inaccuracies in your Personal Information.
              </li>
              <li>
                Delete your Personal Information.
              </li>
              <li>
                Withdraw your consent to the processing of your Personal Information.
              </li>
              <li>
                Object to the processing of your Personal Information.
              </li>
              <li>
                Receive your Personal Information in a structured, commonly used,
            and machine-readable format.
              </li>
              <li>
                Lodge a complaint with a data protection authority if you believe your
            rights have been violated.
              </li>
            </ol>
            To exercise any of these rights, please contact us using the contact
            information provided below
          </li>
          <li>
            Changes to this Privacy Policy <br>
            We may update this Privacy Policy from time to time to reflect changes in our
            practices or for other operational, legal, or regulatory reasons. Any changes
            will be effective when posted, and your continued use of our Services after the
            revised Privacy Policy is posted signifies your acceptance of the changes.

          </li>
          <!-- <li>
            Penyimpanan Informasi Pribadi <br />
            <ol>
              <li class="set4 text-justify">
                Informasi Pribadi Anda hanya akan disimpan selama diperlukan
                untuk memenuhi tujuan dari pengumpulannya, atau selama
                penyimpanan tersebut diperlukan atau diperbolehkan oleh
                peraturan perundang-undangan yang berlaku. Kami akan berhenti
                menyimpan Informasi Pribadi, atau menghapus maksud dari
                dikaitkannya Informasi Pribadi tersebut dengan Anda sebagai
                Merchant atau Konsumen, segera setelah dianggap bahwa tujuan
                pengumpulan Informasi Pribadi tersebut tidak lagi dibutuhkan
                dengan menyimpan Informasi Pribadi dan penyimpanan tidak lagi
                diperlukan untuk tujuan bisnis atau secara hukum.
              </li>
              <li class="set4 text-justify">
                Mohon diperhatikan bahwa masih ada kemungkinan bahwa beberapa
                Informasi Pribadi Anda disimpan oleh pihak lain dengan termasuk
                institusi pemerintah cara tertentu. Dalam hal kami membagikan
                Informasi Pribadi Anda kepada institusi pemerintah yang
                berwenang dan/atau institusi lainnya yang dapat ditunjuk oleh
                pemerintah yang berwenang atau memiliki kerjasama dengan kami,
                Anda menyetujui dan mengakui bahwa penyimpanan Informasi Pribadi
                Anda oleh institusi terkait akan mengikuti kebijakan penyimpanan
                data masing-masing institusi tersebut.
              </li>
            </ol>
          </li>
          <li>
            Kami tunduk pada aturan yang berlaku <br />
            <ol>
              <li class="set5 text-justify">
                Tunduk pada peraturan perundang-undangan yang berlaku, Anda
                dapat meminta Kami untuk mengakses, mengoreksi dan memperbaharui
                Informasi Pribadi Anda yang berada dalam penguasaan Kami, dengan
                menghubungi Kami di perincian yang disediakan di Poin 13 dari
                Kebijakan Privasi ini.
              </li>
              <li class="set5 text-justify">
                Kami berhak menolak permintaan Anda untuk memperbaiki, sebagian
                atau semua Informasi Pribadi Anda yang Kami miliki atau kuasai
                jika diizinkan atau diperlukan berdasarkan perundang-undangan
                yang berlaku. Hal ini termasuk dalam keadaan di mana Informasi
                Pribadi tersebut dapat berisi referensi kepada orang lain atau
                di mana permintaan untuk akses atau permintaan untuk mengoreksi
                adalah untuk alasan yang kami anggap tidak relevan, tidak
                serius, atau menyulitkan. Sebaliknya, Kami dapat menghapus
                Informasi Pribadi sesuai kebijaksanaan Kami, sehingga Anda harus
                melakukan pencatatan sendiri, dan tidak mengandalkan penyimpanan
                Informasi Pribadi atau data lainnya oleh Kami.
              </li>
            </ol>
          </li>
          <li>
            Keamanan dan Perlindungan Informasi Pribadi <br />
            <span class="text-justify"
              >Kerahasiaan Informasi Pribadi Anda adalah hal yang terpenting
              bagi Kami. Kami akan senantiasa memberikan upaya keamanan yang
              wajar dan maksimal untuk melindungi dan mengamankan seluruh
              Informasi Pribadi Anda yang Kami kumpulkan secara online baik
              melalui aplikasi dan situs Kami, dari akses, pengumpulan,
              penggunaan atau pengungkapan oleh orang-orang yang tidak berwenang
              dan dari pengolahan yang bertentangan dengan hukum, kehilangan
              yang tidak disengaja, pemusnahan dan kerusakan atau risiko serupa.
              Seluruh Informasi Pribadi yang Kami kumpulkan akan dilindungi dan
              dijaga kerahasiaannya, sesuai dengan ketentuan hukum yang berlaku. </span
            ><br />
            <span class="text-justify"
              >Kami menjamin bahwa dengan ini memenuhi ketentuan standar PCI-DSS
              dan ISO 27001, yang berkaitan dengan keamanan dan data kartu,
              termasuk terhadap perlindungan, penjagaan kerahasiaan dan juga
              pemusnahan informasi. Selain itu, situs Kami telah menggunakan
              Advanced Encryption Standard (AES) 256 dan enkripsi Transport
              Layer Security (TLS) untuk perlindungan informasi Anda. Namun,
              pengiriman informasi melalui internet tidak sepenuhnya aman. Walau
              Kami akan berusaha sebaik mungkin untuk melindungi Informasi
              Pribadi Anda, Anda mengakui bahwa Kami tidak dapat menjamin
              keutuhan dan keakuratan Informasi Pribadi apa pun yang Anda
              kirimkan melalui Internet, atau menjamin bahwa Informasi Pribadi
              tersebut tidak akan dicegat, diakses, diungkapkan, diubah atau
              dihancurkan oleh pihak ketiga yang tidak berwenang, karena
              faktor-faktor di luar kendali Kami. Anda bertanggung jawab untuk
              menjaga kerahasiaan detail Akun Anda serta kode autentikasi yang
              dikirimkan kepada nomor telepon Anda sehubungan dengan transaksi
              yang Anda lakukan melalui situs Kami, dengan siapapun dan harus
              selalu menjaga dan bertanggung jawab atas keamanan perangkat yang
              Anda gunakan.</span
            >
          </li>
          <li class="text-justify">
            Perubahan terhadap Kebijakan Privasi <br />
            Kami dapat meninjau dan mengubah Kebijakan Privasi ini atas
            kebijakan Kami sendiri untuk memastikan bahwa Kebijakan Privasi ini
            konsisten dengan perkembangan Kami di masa depan, dan/atau perubahan
            persyaratan hukum atau peraturan dari waktu ke waktu. Anda setuju
            bahwa perubahan tersebut akan menggantikan Kebijakan Privasi
            sebelumnya dan berlaku surut. Jika Kami memutuskan untuk mengubah
            Kebijakan Privasi ini, Kami akan memberitahu Anda tentang perubahan
            tersebut melalui pemberitahuan umum yang dipublikasikan pada
            Aplikasi dan/atau situs web, atau sebaliknya ke alamat e-mail Anda
            yang tercantum dalam akun Anda.
          </li>
          <li class="text-justify">
            Anda setuju bahwa Anda bertanggung jawab untuk meninjau Kebijakan
            Privasi ini secara teratur untuk informasi terbaru tentang
            pengolahan data dan praktik perlindungan data Kami, dan bahwa dengan
            Anda terus menggunakan Layanan kami, berkomunikasi dengan kami, atau
            mengakses dan menggunakan Layanan setelah adanya perubahan apapun
            terhadap Kebijakan Privasi ini akan dianggap sebagai persetujuan
            Anda terhadap Kebijakan Privasi ini dan segala perubahannya.
            PENGGUNAAN ANDA ATAS LAYANAN KAMI SETELAH TANGGAL EFEKTIF PERUBAHAN
            APAPUN TERHADAP KEBIJAKAN PRIVASI INI MERUPAKAN PERSETUJUAN ANDA
            ATAS KEBIJAKAN PRIVASI INI DAN SEGALA PERUBAHANNYA
          </li>
          <li>
            <ol>
              <li class="set9 text-justify">
                Dengan mengakses situs Kami, melakukan pendaftaran, dan
                menggunakan Layanan Kami, Anda mengakui bahwa Anda telah membaca
                dan memahami Kebijakan Privasi ini dan menyetujui segala
                ketentuannya. Secara khusus, Anda setuju dan memberikan
                persetujuan kepada Kami untuk mengumpulkan, menggunakan,
                membagikan, mengungkapkan, menyimpan, mentransfer, atau mengolah
                Informasi Pribadi Anda sesuai dengan Kebijakan Privasi ini.
              </li>
              <li class="set9 text-justify">
                Dalam keadaan di mana Anda memberikan kepada Kami Informasi
                Pribadi yang berkaitan dengan individu lain (seperti Informasi
                Pribadi yang berkaitan dengan pasangan anda, anggota keluarga,
                teman, atau pihak lain), Anda menyatakan dan menjamin bahwa Anda
                telah memperoleh persetujuan dari individu tersebut untuk, dan
                dengan ini menyetujui atas nama individu tersebut untuk,
                pengumpulan, penggunaan, pengungkapan dan pengolahan Informasi
                Pribadi mereka oleh Kami.
              </li>
              <li class="set9 text-justify">
                Anda dapat menarik persetujuan Anda untuk setiap atau segala
                pengumpulan, penggunaan atau pengungkapan Informasi Pribadi Anda
                dengan memberikan pemberitahuan yang wajar kepada Kami secara
                tertulis menggunakan rincian kontak yang disebutkan dalam Poin
                13 Kebijakan Privasi ini. Tergantung pada keadaan dan sifat
                persetujuan yang Anda tarik, Anda harus memahami dan mengakui
                bahwa setelah penarikan persetujuan tersebut, Anda mungkin tidak
                lagi dapat menggunakan Layanan. Penarikan persetujuan Anda dapat
                mengakibatkan penghentian Akun Anda atau hubungan kontraktual
                Anda dengan kami, dengan semua hak dan kewajiban yang muncul
                sepenuhnya harus dipenuhi. Setelah menerima pemberitahuan untuk
                menarik persetujuan untuk pengumpulan, penggunaan atau
                pengungkapan Informasi Pribadi Anda, Kami akan menginformasikan
                Anda tentang konsekuensi yang mungkin terjadi dari penarikan
                tersebut sehingga Anda dapat memutuskan apakah Anda tetap ingin
                menarik persetujuan atau tidak.
              </li>
            </ol>
          </li> -->
        </ol>
        <!-- <p class="text-justify">
          Dengan mengunjungi dan/atau mendaftar Akun pada Layanan Kami, Anda
          dan/atau orang tua, wali atau pengampu Anda (jika Anda berusia dibawah
          18 (delapan belas) tahun) menerima dan menyetujui pendekatan dan
          cara-cara yang digambarkan dalam Kebijakan Privasi ini
        </p> -->
      </div>
    </div>
  </div>
  </div>
</template>
<script>
export default {
    computed:{
        lang: function() {
            return this.$store.state.language;
        },
    },
}
</script>
<style scoped>
.set1::marker {
  content: "1." counter(list-item) ". ";
}
.set2::marker {
  content: "2." counter(list-item) ". ";
}
.set3::marker {
  content: "3." counter(list-item) ". ";
}
.set4::marker {
  content: "4." counter(list-item) ". ";
}
.set5::marker {
  content: "5." counter(list-item) ". ";
}
.set9::marker {
  content: "9." counter(list-item) ". ";
}
.breadcrumb-item{
    font-size:1rem
}
.datasection {
  background-image: url("../../assets/img/bgdata.png");
  background-repeat: repeat;
}
.breadcrumb {
  background-color: transparent !important;
  justify-content: end;
  font-size: 10px;
}
.breadcrumb a {
  color: #000 !important;
  font-weight: 700;
  text-decoration: none !important;
  font-size: 1rem;
}
.breadcrumb .active {
  font-weight: 600;
}
@media (max-width: 500px) {
}
@media (min-width: 767px) {
  .breadcrumb {
    font-size: 1rem;
  }
}
</style>
