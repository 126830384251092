<template>
  <div class="datasection">
    <div class="bg-black ">
      <div class="container p-2 p-2rem">
        <h3 class="w-100 text-center partner-kami" v-if="lang === 'ind'"><b>Partner Kami</b></h3>
        <h3 class="w-100 text-center partner-kami" v-else-if="lang === 'eng'"><b>Our Partners</b></h3>
        <div class="partner-list pb-lg-1">
          <div class="d-flex pt-1">
            <a href="https://dev.artrade.id/" target="_blank" rel="noopener noreferrer">
              <figure class="partner px-2 px-lg-4">
                <img class="p-2 p-md-3" src="../../assets/img/logo-artrade.png" alt="" />
              </figure>
            </a>
            <a href="https://balimall.id/" target="_blank" rel="noopener noreferrer">
              <figure class="partner px-2 px-lg-4">
                <img class="p-2 p-md-3" src="../../assets/img/balimall.png" alt="" />
              </figure>
            </a>
          </div>
        </div>
      </div>
    </div>
    <h3 class="text-center pt-5" v-if="lang === 'ind'">
      <b
        >Apapun transaksinya,
        <span class="bold">Art<span class="text-danger">P</span>ay</span>
        solusinya</b
      >
    </h3>
    <h3 class="text-center pt-5" v-else-if="lang === 'eng'">
      <b
        >
        <span class="bold">Art<span class="text-danger">P</span>ay</span>
        has the solution for every transaction.</b
      >
    </h3>
    <p class="text-center text-danger" v-if="lang === 'ind'">
      <b>Setiap transaksi jadi mudah dengan Artpay</b>
    </p>
    <p class="text-center text-danger" v-if="lang === 'eng'">
      <b>Every transaction is easy with Artpay</b>
    </p>
    <div class="container mt-md-5 pb-5">
      <div class="row" v-if="lang === 'ind'">
        <div class="col-md-6 data-artpay">
          <div class="card h-100 p-3 p-md-4">
            <h5><b>PEMBAYARAN ONLINE</b></h5>
            <p class="text-justify">
              Terima pembayaran online melalui metode pembayaran utama di
              Indonesia. Mencakup virtual account, e-money, kartu kredit, gerai
              retail, dan layanan paylater. Memaksimalkan penjualan bisnis Anda
              secara online, in-app, dan in-store.
            </p>
            <router-link to="/pembayaran-online" class="text-danger"
              ><b>Selengkapnya...</b></router-link
            >
            <!-- <figure>
                            <img src="../../assets/img/PG_cart1.png" alt="">
                        </figure> -->
          </div>
        </div>
        <div class="col-md-6 data-artpay margin-top-data-artpay">
          <div class="card h-100 p-3 p-md-4">
            <h5><b>DOMPET DIGITAL</b></h5>
            <p class="text-justify">
              Mau bayar apapun jadi semudah lewat sentuhan jari. Lewat Artpay,
              kamu bisa melakukan transaksi apapun dengan praktis mulai dari
              pembayaran tagihan, transaksi barcode scan, hingga e-commerce.
              #GantiDompet dan nikmati metode pembayaran yang lebih cepat,
              praktis dan aman.
            </p>
            <router-link to="/dompet-digital" class="text-danger"
              ><b>Selengkapnya...</b></router-link
            >
            <!-- <figure>
                            <img src="../../assets/img/disb_cart_21.png" alt="">
                        </figure> -->
          </div>
        </div>
      </div>
      <div class="row" v-else-if="lang === 'eng'">
        <div class="col-md-6 data-artpay">
          <div class="card h-100 p-3 p-md-4">
            <h5><b>ONLINE PAYMENTS</b></h5>
            <p class="text-justify">
              Accept online payments through Indonesia's major payment methods,
including virtual accounts, e-money, credit cards, retail outlets, and pay later
services. Maximize your online, in-app, and in-store sales effortlessly.
            </p>
            <router-link to="/pembayaran-online" class="text-danger"
              ><b>[Learn
More...]</b></router-link
            >
            <!-- <figure>
                            <img src="../../assets/img/PG_cart1.png" alt="">
                        </figure> -->
          </div>
        </div>
        <div class="col-md-6 data-artpay margin-top-data-artpay">
          <div class="card h-100 p-3 p-md-4">
            <h5><b>DIGITAL WALLET</b></h5>
            <p class="text-justify">
              Make paying for anything as easy as a touch. With ArtPay, you can
conveniently conduct various transactions, from bill payments to barcode
scans and e-commerce, all at your fingertips. #SwitchToArtPay and enjoy
faster, more convenient, and secure payment methods.
            </p>
            <router-link to="/dompet-digital" class="text-danger"
              ><b>[Learn
More...]</b></router-link
            >
            <!-- <figure>
                            <img src="../../assets/img/disb_cart_21.png" alt="">
                        </figure> -->
          </div>
        </div>
      </div>
    </div>
    <div class="listcard pt-5 mt-md-5 mb-md-5 pb-5">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-lg-6 col-xl-6 data-artpay">
            <div class="h-100 p-2 p-md-4" v-if="lang === 'ind'">
              <h5>
                <b
                  >Dapatkan <span class="text-danger">Pelanggan</span> Lebih
                  Banyak</b
                >
              </h5>
              <p class="text-justify">
                Cukup sekali daftar, bisnis Anda otomatis terkoneksi ke beragam
                metode pembayaran. Buat pelanggan lebih nyaman bertransaksi
              </p>
            </div>
            <div class="h-100 p-2 p-md-4" v-else-if="lang === 'eng'">
              <h5>
                <b
                  >Get More <span class="text-danger">Customers</span> </b
                >
              </h5>
              <p class="text-justify">
                Sign up once, and your business automatically connects to various payment
methods, making it more convenient for customers to transact.
              </p>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xl-6 databank">
            <div class="h-100">
              <figure>
                <img src="../../assets/img/bankdata.jpg" alt="" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container pt-md-5 pb-5 mt-5">
      <div class="row">
        <div class="col-lg-6 data-artpay">
          <div class="h-100 p-2 p-md-4" v-if="lang ==='ind'">
            <h5>
              <b
                >Selalu ada untuk
                <span class="text-danger">membantu</span> Anda</b
              >
            </h5>
            <p class="text-justify">
              Apabila Anda ingin mengetahui lebih lanjut mengenai produk
              pembayaran. Kami selalu siap membantu Anda. Hubungi kami lewat
              media sosial atau tombol chat di sebelah kanan. Anda juga dapat
              mengunjungi Pusat Bantuan kami.
            </p>
          </div>
          <div class="h-100 p-2 p-md-4" v-else-if="lang === 'eng'">
            <h5>
              <b
                >Always Here to
                <span class="text-danger">Assist</span> You</b
              >
            </h5>
            <p class="text-justify">
              If you'd like to learn more about our payment products, we're always ready to
help. Contact us through social media or the chat button on the right. You can
also visit our Help Center.
ArtPay is a technology company dedicated to distributing all types of
payments with proven service excellence.
            </p>
          </div>
        </div>
        <div class="col-lg-6 databank">
          <div class="h-100 p-2 p-md-4">
            <div id="accordion" v-if="lang === 'ind'">
              <div class="card">
                <div class="card-header p-card-header" id="headingOne">
                  <h5 class="mb-0">
                    <button
                      @click="collapse('One')"
                      aria-expanded="true"
                      id="btnOne"
                      class="btn btn-md btn-accordion w-100"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-controls="collapseOne"
                    >
                      Apa itu Art<span class="text-danger">P</span>ay?
                    </button>
                  </h5>
                </div>

                <div
                  id="collapseOne"
                  class="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div class="card-body text-justify">
                    ArTpay adalah perusahaan teknologi yang bekerja untuk mendistribusikan semua
jenis pembayaran dengan kinerja layanan teruji dan terbukti.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header p-card-header" id="headingTwo">
                  <h5 class="mb-0">
                    <button
                      @click="collapse('Two')"
                      aria-expanded="false"
                      id="btnTwo"
                      class="btn btn-accordion collapsed w-100"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-controls="collapseTwo"
                    >
                      Berapa Biaya yang dikenakan Art<span class="text-danger"
                        >P</span
                      >ay
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseTwo"
                  class="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordion"
                >
                  <div class="card-body text-justify">
                    Artpay tidak memungut biaya registrasi ataupun biaya
                    perawatan sistem. Biaya akan dikenakan dari setiap transaksi
                    berhasil. Cek disini untuk daftar harga layanan dari setiap
                    metode pembayaran.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header p-card-header" id="headingThree">
                  <h5 class="mb-0">
                    <button
                      @click="collapse('Three')"
                      aria-expanded="false"
                      id="btnThree"
                      class="btn btn-accordion collapsed w-100"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-controls="collapseThree"
                    >
                      Bagaimana Art<span class="text-danger">P</span>ay dapat
                      membantu bisnis anda?
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseThree"
                  class="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordion"
                >
                  <div class="card-body text-justify">
                    Anda tidak perlu bingung lagi bagaimana menerima pembayaran
                    secara online. Artpay akan mengatasi itu semua, dengan
                    proses integrasi yang mudah akan menjadikan Artpay solusi
                    pembayaran online anda.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header p-card-header" id="HeadingFour">
                  <h5 class="mb-0">
                    <button
                      @click="collapse('Four')"
                      aria-expanded="false"
                      id="btnFour"
                      class="btn btn-accordion collapsed w-100"
                      data-toggle="collapse"
                      data-target="#collapseFour"
                      aria-controls="CollapseFour"
                    >
                      Bagaimana cara menggunakan Art<span class="text-danger"
                        >P</span
                      >ay?
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseFour"
                  class="collapse"
                  aria-labelledby="HeadingFour"
                  data-parent="#accordion"
                >
                  <div class="card-body text-justify">
                    ArTpay menawarkan fasilitas pembayaran dari banyak bank dan
                    sistem pembayaran online yang tersedia di Indonesia. Dengan
                    ArTpay Anda dapat menerima pembayaran dari konsumen
                    menggunakan Qris, Ovo, Dana, Gopay, Shopeepay atau kartu
                    debit dan kredit dari beberapa bank tanpa harus membuka akun
                    atau rekening pembayaran online di bank tersebut. ArTpay
                    hadir untuk mempermudah bisnis anda, sehingga tidak perlu
                    khawatir lagi menerima transaksi cashless. ArTpay memiliki
                    lini produk terlengkap dan jaringan kemitraan yang luas,
                    ArTpay berkomitmen mendorong kinerja bisnis dan
                    memaksimalkan potensi bisnis mitra kami, mari hasilkan
                    keuntungan Optimal bersama ArTpay.
                  </div>
                </div>
              </div>
            </div>
            <div id="accordion" v-else-if="lang === 'eng'">
              <div class="card">
                <div class="card-header p-card-header" id="headingOne">
                  <h5 class="mb-0">
                    <button
                      @click="collapse('One')"
                      aria-expanded="true"
                      id="btnOne"
                      class="btn btn-md btn-accordion w-100"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      aria-controls="collapseOne"
                    >
                      what is Art<span class="text-danger">P</span>ay?
                    </button>
                  </h5>
                </div>

                <div
                  id="collapseOne"
                  class="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div class="card-body text-justify">
                    ArTpay is a technology company that works to distribute all
type of payment with tested and proven service performance.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header p-card-header" id="headingTwo">
                  <h5 class="mb-0">
                    <button
                      @click="collapse('Two')"
                      aria-expanded="false"
                      id="btnTwo"
                      class="btn btn-accordion collapsed w-100"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-controls="collapseTwo"
                    >
                      How much does Art<span class="text-danger"
                        >P</span
                      >ay charge?
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseTwo"
                  class="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordion"
                >
                  <div class="card-body text-justify">
                    Artpay does not charge registration fees or fees
                     system maintenance. Fees will be charged for each transaction
                     succeed. Check here for a price list for each service
                     payment method.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header p-card-header" id="headingThree">
                  <h5 class="mb-0">
                    <button
                      @click="collapse('Three')"
                      aria-expanded="false"
                      id="btnThree"
                      class="btn btn-accordion collapsed w-100"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      aria-controls="collapseThree"
                    >
                      how Art<span class="text-danger">P</span>ay  can
                       help your business?
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseThree"
                  class="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordion"
                >
                  <div class="card-body text-justify">
                    You no longer need to be confused about how to receive payments
                     by online. Artpay will handle it all, with
                     an easy integration process will make Artpay the solution
                     your online payment.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header p-card-header" id="HeadingFour">
                  <h5 class="mb-0">
                    <button
                      @click="collapse('Four')"
                      aria-expanded="false"
                      id="btnFour"
                      class="btn btn-accordion collapsed w-100"
                      data-toggle="collapse"
                      data-target="#collapseFour"
                      aria-controls="CollapseFour"
                    >
                      How to use Art<span class="text-danger"
                        >P</span
                      >ay?
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseFour"
                  class="collapse"
                  aria-labelledby="HeadingFour"
                  data-parent="#accordion"
                >
                  <div class="card-body text-justify">
                   ArTpay offers payment facilities from many banks and
                     online payment system available in Indonesia. With
                     ArTpay You can accept payments from consumers
                     using Qris, Ovo, Dana, Gopay, Shopeepay or card
                     debit and credit from several banks without having to open an account
                     or an online payment account at the bank. ArTpay
                     is here to make your business easier, so you don't have to
                     worried about accepting cashless transactions. ArTpay has
                     the most complete product line and extensive partnership network,
                     ArTpay is committed to driving business performance and
                     maximize the business potential of our partners, let's make it happen
                     Optimal profits with ArTpay.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      accrodionbefore: "",
      accrodionlist: "One",
      collapsedata: 0,
    };
  },
  computed:{
        lang: function() {
            return this.$store.state.language;
        },
    },
  methods: {
    collapse(data) {
      // untuk memasukkan data saat ini ke data terakhir supaya bisa ditutup
      this.accrodionbefore = this.accrodionlist;
      //   memasukkan data baru dari @click ke data saat ini
      this.accrodionlist = data;
      //   mengecek apakah terdapat data lama supaya tidak error pada saat peralihan menutup semua accordion lalu membuka accordion baru
      if (this.accrodionbefore != "") {
        document.getElementById("btn" + this.accrodionbefore).click();
      }
      //   membuka accordion yang diinginkan
      document.getElementById("btn" + data).click();
      //   membuka accordion yang diinginkan
      if (data != this.accrodionbefore) {
        document.getElementById("btn" + data).click();
      }
      //   melakukan pengecekan apakah klik yang terbaru adalah menutup atau membuka accordion
      var databutton = document.getElementById("btn" + data).classList;
      databutton.forEach((item) => {
        // jika ternyata menutup maka data accordion saat ini dan yang lama dihapus supaya nanti pada saat membuka accordion baru tidak error
        if (item == "collapsed") {
          this.accrodionbefore = "";
          this.accrodionlist = "";
        }
      });
    },
  },
};
</script>
<style scoped>
.bg-black {
  background-color: #2b2a2d !important;
  color: #fff;
}

.p-card-header {
  padding: 0 !important;
}
.datasection {
  background-image: url("../../assets/img/bgdata.png");
  background-repeat: repeat;
}
.data-artpay figure {
  width: 100px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
}
.data-artpay p {
  position: relative;
  z-index: 100;
}
.data-artpay figure img {
  width: 100%;
}
.listcard {
  background-color: #fff;
}
.databank figure img {
  width: 100%;
}
.btn-accordion {
  text-align: left !important;
}
.card-header button {
  padding: 0.75rem 1.25rem !important;
  text-decoration: none !important;
  color: #fff !important;
  background-color: #000;
}
.partner-list {
  margin: 0 auto;
  display: block;
}
.partner-list .d-flex {
  justify-content: center;
}
.partner-list img {
  background-color: #fff !important;
  border-radius: 5px;
}
.partner {
  margin-bottom: 0.5rem !important;
  width: 110px;
}
.partner img {
  width: 100%;
}
.partner-kami {
  font-size: 0.9rem;
}
@media (max-width: 370px) {
   .btn-accordion.collapsed:after {
    content: "";
    background-image: url('../../assets/img/rightarrow.png');
    background-size: cover;
    width: 20px;
    height: 20px;
    right: 10px;
    position: absolute;
    top: 35%;
    }
    .btn-accordion:after {
    content: "";
    background-image: url('../../assets/img/downarrow.png');
    background-size: cover;
    width: 20px;
    height: 20px;
    right: 10px;
    position: absolute;
    top: 7%;
    }
}
@media (max-width: 500px) {
  .margin-top-data-artpay {
    margin-top: 2rem;
  }
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
  .partner-kami {
    font-size: 1rem;
  }
  .btn-accordion.collapsed:after {
  content: "";
  background-image: url('../../assets/img/rightarrow.png');
  background-size: cover;
  width: 25px;
  height: 25px;
}
 .btn-accordion:after {
  content: "";
  background-image: url('../../assets/img/downarrow.png');
  background-size: cover;
  width: 25px;
  height: 25px;
  font-weight: 900;
  float: right;
}
}

@media (min-width: 424px) {
  .partner-kami {
    font-size: 1.5rem;
  }
  .partner {
    width: 130px;
  }
}

@media (min-width: 767px) {
  .partner {
    width: 250px;
  }
  .partner img {
    width: 100%;
  }
  .p-2rem {
    padding: 2rem !important;
  }
}

@media (min-width: 1023px) {
  .databank figure {
    width: 100%;
  }
  .partner {
    width: 250px;
  }
}

@media (min-width: 1439px) {
  /* .partner {
    width: 400px;
  } */
}

@media (min-width: 2000px) {
}
</style>
