<template>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.text-justify{
    text-align: justify !important;
}
.b-radius-card{
  border-radius: 15px !important;
  box-shadow: 5px 5px 20px 2px rgba(0, 0, 0, 0.1);
}
.datasection h3,.firsttry h3{
    font-size: 1.3rem;
}
.datasection h4, .firsttry h4{
    font-size: 1.2rem;
}
.datasection h5, .firsttry h5{
    font-size: 1.1rem;
}
.datasection p,.datasection a, .firsttry p, .firsttry a{
    font-size: 1rem;
}
@media (min-width: 350px) {
    .datasection h3, .firsttry h3{
        font-size: 1.3rem;
    }
    .datasection h4, .firsttry h4{
        font-size: 1.2rem;
    }
    .datasection h5, .firsttry h5{
        font-size: 1.1rem;
    }
    .datasection p,.datasection a, .firsttry p, .firsttry a{
        font-size: 1rem;
    }
}
@media (min-width: 400px) {
    .datasection h3, .firsttry h3{
        font-size: 1.5rem;
    }
    .datasection h4, .firsttry h4{
        font-size: 1.35rem;
    }
    .datasection h5, .firsttry h5{
        font-size: 1.2rem;
    }
    .datasection p,.datasection a, .firsttry p, .firsttry a{
        font-size: 1rem ;
    }
}
@media (min-width: 700px) {
    .datasection h3, .firsttry h3{
        font-size: 1.75rem;
    }
    .datasection h4, .firsttry h4{
        font-size: 1.5rem;
    }
    .datasection h5, .firsttry h5{
        font-size: 1.25rem;
    }
    .datasection p,.datasection a, .firsttry p, .firsttry a{
        font-size: 1rem ;
    }
}
</style>
