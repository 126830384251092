<template>
  <div>
    <div class="w-100 pt-3 pt-md-4">
      <button type="button" data-toggle="modal" data-target="#modalFilter" class="btn btn-danger btn-top">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" style="transform: ;msFilter:;"><path d="M21 3H5a1 1 0 0 0-1 1v2.59c0 .523.213 1.037.583 1.407L10 13.414V21a1.001 1.001 0 0 0 1.447.895l4-2c.339-.17.553-.516.553-.895v-5.586l5.417-5.417c.37-.37.583-.884.583-1.407V4a1 1 0 0 0-1-1zm-6.707 9.293A.996.996 0 0 0 14 13v5.382l-2 1V13a.996.996 0 0 0-.293-.707L6 6.59V5h14.001l.002 1.583-5.71 5.71z"></path></svg><b>FILTER</b>
      </button>
    </div>
    <div class="w-100 datatable">
      <table class="table table-striped table-web mt-2 mt-4">
        <thead class="thead-light text-white">
          <tr>
            <th scope="col">No</th>
            <th scope="col" style="min-wisth: 150px !important">
              ID Transaksi
            </th>
            <th scope="col" style="min-wisth: 150px !important">Tanggal</th>
            <th scope="col">Status</th>
            <th scope="col">Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">1</th>
            <td><span class="transaksi-data">#ABC1234</span></td>
            <td>12 Januari 2023</td>
            <td><span class="color-warning p-1">Menunggu</span></td>
            <td>
              <router-link to="/profile/detail-transaksi" class="danger-btn text-danger"
                >DETAIL</router-link
              >
            </td>
          </tr>
          <tr>
            <th scope="row">2</th>
            <td><span class="transaksi-data">#ABC1234</span></td>
            <td>12 Januari 2023</td>
            <td><span class="color-warning p-1">Menunggu</span></td>
            <td>
              <router-link to="/profile/detail-transaksi" class="danger-btn text-danger"
                >DETAIL</router-link
              >
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table table-mobile mt-2 mt-4">
        <thead class="thead-light text-white">
          <tr>
            <th scope="col"></th>
            <th scope="col" style="min-width: 125px !important">
              ID Transaksi
            </th>
            <th scope="col">Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr class="border-bottom-nol">
            <th scope="row">
              <div class="" id="headingTwo">
                  <h5 class="mb-0">
                    <button class="btn w-100 collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;"><path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path></svg>
                    </button>
                  </h5>
                </div>
            </th>
            <td class="pt-4"><span class="transaksi-data">#ABC1234</span> </td>
            <td class="pt-4">
              <router-link to="/profile/detail-transaksi" class="danger-btn text-danger"
                >DETAIL</router-link
              >
            </td>
          </tr>
          <tr>
            <td colspan="3" class="border-top-nol">
              <div id="accordion" class="w-100">
              <div class="card">
                
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div class="card-body">
                    <h5><b>Tanggal</b></h5>
                    <p>12 Januari 2023</p>
                    <h5><b>Status</b></h5>
                    <p><span class="color-warning p-1">Menunggu</span></p>
                  </div>
                </div>
              </div>
            </div>
            </td>
          </tr>
          <tr class="border-null">
            <th scope="row">
              <div class="" id="headingThree">
                  <h5 class="mb-0">
                    <button class="btn w-100 collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;"><path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path></svg>
                    </button>
                  </h5>
                </div>
            </th>
            <td class="pt-4"><span class="transaksi-data">#ABC1234</span></td>
            <td class="pt-4">
              <router-link to="/profile/detail-transaksi" class="danger-btn text-danger"
                >DETAIL</router-link
              >
            </td>
          </tr>
          <tr>
            <td colspan="3" class="border-top-nol">
              <div id="accordion" class="w-100">
              <div class="card">
                
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                  <div class="card-body">
                    <h5><b>Tanggal</b></h5>
                    <p>12 Januari 2023</p>
                    <h5><b>Status</b></h5>
                    <p><span class="color-warning p-1">Menunggu</span></p>
                  </div>
                </div>
              </div>
            </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
     <div class="modal fade" id="modalFilter" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Filter Data</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="form-regis" ref="registrasi">
                    <div class="row">
                        <div class="col-md mt-3">
                            <label for="idtransaksi">ID Transaksi</label>
                            <input id="idtransaksi" type="text" class="form-control radius" placeholder="ID Transaksi">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md mt-3">
                            <label for="tanggal">Tanggal</label>
                            <input id="tanggal" type="date" class="form-control radius" placeholder="Username">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md mt-3">
                            <label for="Status">Status</label>
                            <select class="form-control" id="Status">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                    <div class="float-right mt-3">
                        <a href="" type="button" class="btn btn-secondary mr-2" data-dismiss="modal" aria-label="Close">BATAL</a>
                        <button type="button" class="btn btn-danger">FILTER</button>
                    </div>
                </form>
            </div>
            </div>
        </div>
        </div>
  </div>
</template>
<style scoped>
.table-striped > tbody > tr:nth-child(2n+1) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
   background-color: #fff;
}
.table-striped > tbody >  tr:nth-child(odd) {
    background-color: #fff !important;
    }

 .table-striped > tbody >  tr:nth-child(even) {
    background-color: rgb(254, 243, 245);
    }

.btn-top svg{
  fill: #fff;
}
.btn-top svg:hover{
  fill: #fff;
}
.btn-top{
  line-height: 1.5;
}
.btntop {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.datatable {
  overflow: hidden;
}
.bagde {
  border-radius: 10px;
}
.datatable {
  overflow-x: auto;
}
.datatable .btn-danger{
  display: initial;
}
.border-bottom-nol{
  border-bottom: 0px !important;
}
.border-top-nol{
  border-top: 0px !important;
}
.table-mobile{
  display: table;
}
.table-web{
  display: none;
}
.pt-0{
  padding-top: 0px !important;
}
.border-null th, .border-null td{
  border: 0px !important;
  border-top: 0px !important;
  border-bottom: 0px !important;
}
.color-warning{
  color: #ffc107 !important;
}
.thead-light th{
  background-color: #f1f2f7 !important;
  border-color: rgba(255, 255, 255, 0.4) !important;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 0.9rem;
}
.table-striped tbody tr:nth-of-type(odd){
  background-color: #f2f1ff !important;
  border-color: rgba(255, 255, 255, 0.4) !important;
}
.table-striped tbody tr td, .table-striped tbody tr th{
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.9rem;
  border-color: rgba(255, 255, 255, 0.4) !important;
}
.transaksi-data{
  font-weight: 500;
  background-color: #f1f2f7;
  color: #969aab;
  padding: 5px;
  border-radius: 3px;
}
.danger-btn{
  background-color: #efc7cb;
  padding: 5px 10px;
  border-radius: 3px;
}
@media (min-width: 767px) {
    .table-mobile{
      display: none;
    }
    .table-web{
      display: table;
    }
}
</style>
