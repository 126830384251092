<template>
    <div class="container pb-md-5">
        <div class="row">
            <div class="col-lg-8 col-xl-9 pt-3">
                <div class="d-section pt-2 pt-md-5"  v-if="lang === 'ind'">
                    <div class="firsttry">
                        <h3><b>Siap untuk transaksi pertama?</b></h3>
                        <h4>Hubungi customer service kami 24/7 untuk mulai saat ini juga.</h4>
                    </div>
                    <div class="pt-md-4 d-btn">
                        <a target="_blank" href="https://api.whatsapp.com/send/?phone=628113137888&text=&type=phone_number&app_absent=0" class="btn btn-hubungi btn-danger p-md-3 p-lg-2 ps-5 pe-5 mb-4">
                            HUBUNGI KAMI
                        </a>
                    </div>
                </div>
                <div class="d-section pt-2 pt-md-5" v-else-if="lang === 'eng'">
                    <div class="firsttry">
                        <h3><b>Ready for your first transaction??</b></h3>
                        <h4>Contact our customer service 24/7 to get started right away</h4>
                    </div>
                    <div class="pt-md-4 d-btn">
                        <a target="_blank" href="https://api.whatsapp.com/send/?phone=628113137888&text=&type=phone_number&app_absent=0" class="btn btn-hubungi btn-danger p-md-3 p-lg-2 ps-5 pe-5 mb-4">
                            CONTACT US
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-xl-3 display-image pt-lg-3">
                <img src="../assets/img/51273141.png" class="w-100" alt="">
            </div>
        </div>
    </div>
</template>
<script>
export default {
     computed:{
        lang: function() {
            return this.$store.state.language;
        },
    },
}
</script>
<style scoped>
.display-image{
    display: none;
}
.firsttry{
    text-align: center;
}
.d-section{
    display: block;
}
.d-btn{
    text-align: center;
}
@media (max-width: 500px) {
    .btn-hubungi{
        font-size: 0.75rem;
    }
}
@media (min-width: 319px) {
}


@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
    .d-section{
        display: flex;
    }
    .firsttry{
        text-align: left;
        max-width: 70%;
    }
    .d-btn{
        text-align: left;
    }
}

@media (min-width: 1023px) {
    .firsttry{
        max-width: 425px;
    }
    .display-image{
        display: block;
    }
}

@media (min-width: 1439px) {
    .firsttry{
        max-width:75%;
    }
}

@media (min-width: 2000px) {
}
</style>