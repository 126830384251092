<template>
    <div>
        <form class="form-regis" ref="registrasi">
            <div class="row">
                <div class="col-md mt-4">
                    <label for="Username">Username</label>
                    <input id="Username" type="text" class="form-control mt-1 radius" placeholder="Username">
                </div>
            </div>
            <div class="row">
                <div class="col-md mt-4">
                    <label for="email">Email</label>
                    <input id="email" type="email" class="form-control mt-1 radius" placeholder="Email">
                </div>
            </div>
            <div class="row">
                <div class="col-md mt-4">
                    <label for="password">Password</label>
                    <input id="password" type="password" class="form-control mt-1 radius" placeholder="Password">
                </div>
            </div>
            <button class="btn btn-danger d-block text-right mt-3">Update</button>
        </form>
    </div>
</template>