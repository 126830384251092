<template>
    <section class="bg-black">
        
        <div class="container section-header pb-md-5">
          <nav aria-label="breadcrumb">
                <ol class="breadcrumb" v-if="lang === 'ind'">
                    <li class="breadcrumb-item"><router-link to="/">Beranda</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">Dompet Digital</li>
                </ol>
                <ol class="breadcrumb" v-else-if="lang === 'eng'">
                    <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">Digital Wallet</li>
                </ol>
            </nav>
            <div class="row ps-md-5 pe-md-5">
                <div class="col-7 col-md-5 ps-md-5 text-white text-md-start mt-md-4" data-aos="fade-right" data-aos-delay="400">
                    <div class="data-left pt-lg-5" v-if="lang === 'ind'">
                        <h4 class="pt-xl-5"><b>Miliki Dompet Digital Sendiri Dalam Aplikasi Bisnis Anda</b></h4>
                        <p class=" lh-base">  Bangun aplikasi untuk fasilitasi transaksi online anggota komunitas atau tambah fitur dompet elektronik dalam aplikasi bisnis</p>
                        <router-link to="/hubungi-kami" class="btn btn-mulai btn-danger p-md-3 ps-5 pe-5 mb-4">
                            MULAI SEKARANG
                        </router-link>
                    </div>
                    <div class="data-left pt-lg-5" v-else-if="lang === 'eng'">
                        <h4 class="pt-xl-5"><b>Have Your Own Digital Wallet in Your Business App.</b></h4>
                        <p class=" lh-base"> Build an app to facilitate online transactions for your community members or
add an electronic wallet feature to your business app.</p>
                        <router-link to="/hubungi-kami" class="btn btn-mulai btn-danger p-md-3 ps-5 pe-5 mb-4">
                            START NOW
                        </router-link>
                    </div>
                </div>
                <div class="col-5 col-md-7 pb-md-5" data-aos="fade-left" data-aos-delay="400">
                    <img src="../../assets/img/e-wallet.svg" class="imgblok" alt="">
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    computed:{
        lang: function() {
            return this.$store.state.language;
        },
    },
}
</script>
<style scoped>
.bg-black{
    background-color: #000 !important;
}
.imgblok{
    display: block;
    margin: 0 auto;
}
.data-left h4{
    font-size: 0.75rem ;
}
.data-left p,.data-left a{
    font-size: 0.5rem ;
}
.breadcrumb{
    background-color: transparent !important;
    justify-content: end;
    font-size: 10px;
}
.breadcrumb a{
    color: #fff !important;
    font-weight: 700;
    text-decoration: none !important;
}
.breadcrumb .active{
    font-weight: 600;
}
.section-header{
    padding-top: 55px;
    overflow-x: hidden
}
@media (max-width:500px) {
    .imgblok{
        width: 100%;
    }
}
@media (min-width: 350px) {
    .data-left h4{
        font-size: 0.9rem;
    }
    .data-left p, .data-left a{
        font-size: 0.7rem;
    }
}
@media (min-width: 400px) {
    .data-left h4{
        font-size: 1rem;
    }
    .data-left p, .data-left a{
        font-size: 0.8rem ;
    }
}
@media (min-width: 700px) {
    .data-left h4{
        font-size: 1.75rem;
    }
    .data-left p, .data-left a{
        font-size: 1rem ;
    }
    .section-header{
        padding-top: 100px;
        overflow-x: hidden
    }
    .breadcrumb{
        font-size: 1rem;
    }
    .btn-mulai{
        margin-top: 1rem;
        padding: 0.5rem 0.6rem !important;
    }
}
</style>