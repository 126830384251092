import { createStore } from 'vuex'

const state = {
  language:'ind',
}

const store = createStore({
  state,
  getters: {
    language: (state) =>{
          return state.language
      },

  },
  actions: {
      setlanguage: ({ commit }, {datalanguage}) => commit('language', datalanguage),
      
  },
  mutations: {
    language(state, language){
          state.language = language
      },
  },
})

export default store
