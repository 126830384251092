<template>
    <div>
        <form class="form-regis" ref="registrasi">
            <div class="row">
                <div class="col-md mt-3">
                    <label for="api">X-api-key</label>
                    <input id="api" type="text" class="form-control radius" placeholder="X-api-key">
                </div>
            </div>
            <div class="row">
                <div class="col-md mt-3">
                    <label for="username">Username</label>
                    <input id="username" type="text" class="form-control radius" placeholder="Username">
                </div>
            </div>
            <div class="row">
                <div class="col-md mt-3">
                    <label for="password">Password</label>
                    <input id="password" type="text" class="form-control radius" placeholder="Password">
                </div>
            </div>
            <div class="w-100 mt-3 lupa-pass">
                <a class="text-danger text-right w-100" type="button" data-toggle="modal" data-target="#modalkredential">Lihat kredensial</a>
            </div>
        </form>
        <div class="modal fade" id="modalkredential" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">INPUT DATA KREDENSIAL</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="form-regis" ref="registrasi">
                    <div class="row">
                        <div class="col-md mt-3">
                            <label for="username">Username</label>
                            <input id="username" type="text" class="form-control radius" placeholder="Username">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md mt-3">
                            <label for="password">Password</label>
                            <input id="password" type="password" class="form-control radius" placeholder="Password">
                        </div>
                    </div>
                    <div class="float-right mt-3">
                        <a href="" type="button" class="btn btn-secondary mr-2" data-dismiss="modal" aria-label="Close">BATAL</a>
                        <button type="button" class="btn btn-danger">LIHAT KREDENSIAL</button>
                    </div>
                </form>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>