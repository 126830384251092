<template>
  <div>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link text-white active"
          id="transaksi-tab"
          data-toggle="tab"
          data-target="#transaksi"
          type="button"
          role="tab"
          aria-controls="transaksi"
          aria-selected="true"
        >
          Detail Transaksi
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link text-white btn"
          id="profile-tab"
          data-toggle="tab"
          data-target="#profile"
          type="button"
          role="tab"
          aria-controls="profile"
          aria-selected="false"
        >
          Disbursement
        </button>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="transaksi"
        role="tabpanel"
        aria-labelledby="transaksi-tab"
      >
        <div class="container">
          <div class="container-fluid px-0 px-md-2 mt-4">
            <span class="text-header-detail"
              ><b>Tanggal</b> :  12 Januari 2023</span
            ><br />
            <span class="text-header-detail"
              ><b>ID Pesanan</b> : #123456</span
            ><br />
            <span class="text-header-detail"><b>Metode Pembayaran</b> :  BCA Virtual Account</span><br/>
            <span class="text-header-detail"><b>Status</b> : </span>
              <span
                class="badge text-light bg-warning"
                >Menunggu</span
              >
              
            <div class="row confirmation-order-info-container web-view mt-3">
              <div class="col-lg-6">
                <div
                  class="confirmation-order-user rounded-lg px-2 py-2 px-md-4 py-md-3"
                >
                  <div class="card_info">
                    <h5>Informasi Pelanggan</h5>
                    <hr />
                    <table class="table-no-border informationTable">
                      <tr>
                        <td>
                          <div class="user_label">
                            <b>Nama</b>
                          </div>
                        </td>
                        <td>:  Bayu Artayoga</td>
                      </tr>
                      <tr>
                        <td>
                          <div class="user_label">
                            <b>Email</b>
                          </div>
                        </td>
                        <td>: arta@gmail.com</td>
                      </tr>
                      <tr>
                        <td>
                          <div class="user_label">
                            <b>Telepon</b>
                          </div>
                        </td>
                        <td>: 081281733707</td>
                      </tr>
                      <tr>
                        <td>
                          <div class="user_label">
                            <b>Alamat</b>
                          </div>
                        </td>
                        <td>: </td>
                      </tr>
                    </table>
                    <!-- <h6>Alamat</h6> -->
                    <p
                      class="px-2"
                    >Perumahan Dalung Permai, Kuta Utara, Badung - Bali 80361
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div
                  class="confirmation-order-ekspedisi px-2 py-2 px-md-4 py-md-3"
                >
                  <div class="card_info">
                    <h5>Informasi Alamat Pengiriman</h5>
                    <hr />
                    <div class="d-flex info_pengiriman">
                      <h6 class="mt-2">No telepon :</h6>
                      <p class="px-2 mt-2">
                        081281733707
                      </p>
                    </div>
                    
                    <h6 class="mt-2">Alamat Cabang :</h6>
                    <p class="px-2 alamat_pengiriman"></p>
                       Perumahan Dalung Permai, Kuta Utara, Badung - Bali 80361
                    
                    <div class="transaksi-footer-bottom"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="confirmation-order-info-container mobile-view">
              <div
                class="confirmation-order-user border border-4 border-light rounded-lg px-3 py-3 px-md-4 py-md-3"
              >
                <div class="">
                  <h5>Informasi Pelanggan</h5>
                  <hr />
                  <div class="table-no-border informationTable">
                    <p>
                      <b>Nama :</b><br />
                      Bayu Artayoga
                    </p>
                    <p>
                      <b>Email :</b><br />
                      artayoga2@gmail.com
                    </p>
                    <p>
                      <b>No Telepon :</b> <br />
                      081281733707
                    </p>
                    <p>
                      <b>Alamat :</b><br />
                      Perumahan Dalung Permai, Kuta Utara, Badung - Bali 80361
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="confirmation-order-ekspedisi mt-3 border border-4 border-light rounded-lg px-3 py-3 px-md-4 py-md-3"
              >
                <div class="">
                  <h5>Informasi Alamat Pengiriman</h5>
                  <hr />
                  <div class="table-no-border informationTable">
                    <p>
                      <b>No telepon :</b><br />
                     081281733707
                    </p>
                    <p class="mt-2" style="margin-bottom: 0px">
                      <b>Alamat Cabang :</b>
                    </p>
                    <p class="px-2 alamat_pengiriman">
                      <b> Perumahan Dalung Permai, Kuta Utara, Badung - Bali 80361</b>
                    </p>
                  </div>
                  <div class="transaksi-footer-bottom">
                    <!-- <router-link to="/oke" class="btn btn-danger float-right">Nego Ongkir</router-link> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container container-produk product-data mt-3 mt-md-5">
            <div
              class="bg-white product-data p-md-3 mb-4"
            >
              <table class="table-product">
                <tr class="header-table">
                  <th class="p-md-3 px-3">Product</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Subtotal</th>
                </tr>
                <tr class="tr-product border-bottom pt-4">
                  <td colspan="2" class="p-3 dataproduk">
                    <div class="d-flex">
                      <!-- <figure class="image mx-1">
                        <img :src="$imgProduct + dataproduct.image" alt="" />
                      </figure> -->
                      <div class="mx-1 detail_product">
                        <h6>Barang A</h6>
                      </div>
                    </div>
                  </td>
                  <td class="td-nonproduk">
                    <div>
                      <h6>
                        Rp 40000
                      </h6>
                      
                    </div>
                  </td>
                  <td>
                    <p class="pt-3">2 pcs</p>
                  </td>
                  <td>
                    <p class="pt-3">
                      Rp
                      80000
                    </p>
                  </td>
                </tr>
                <tr class="tr-product border-bottom pt-4">
                  <td colspan="2" class="p-3 dataproduk">
                    <div class="d-flex">
                      <!-- <figure class="image mx-1">
                        <img :src="$imgProduct + dataproduct.image" alt="" />
                      </figure> -->
                      <div class="mx-1 detail_product">
                        <h6>Barang B</h6>
                      </div>
                    </div>
                  </td>
                  <td class="td-nonproduk">
                    <div>
                      <h6>
                        Rp 25000
                      </h6>
                      
                    </div>
                  </td>
                  <td>
                    <p class="pt-3">1 pcs</p>
                  </td>
                  <td>
                    <p class="pt-3">
                      Rp
                      25000
                    </p>
                  </td>
                </tr>
                <!-- <tr :key="'footer' + index">
                  <td colspan="5">
                    <div v-if="dataproduct?.notes">
                      <span
                        class="bottom-left d-flex"
                        v-if="dataproduct?.notes.length > 50"
                      >
                        <p>
                          <b>Catatan :</b>
                          {{ dataproduct?.notes.substring(0, 50) + "..." }}
                          <span
                            class="readlink"
                            @click.prevent="modalnote(dataproduct?.notes)"
                            >Read more</span
                          >
                        </p>
                      </span>
                      <span
                        class="bottom-left d-flex"
                        v-else-if="dataproduct?.notes.length < 50"
                      >
                        <p><b>Catatan :</b> {{ dataproduct?.notes }}</p>
                      </span>
                    </div>
                    <div v-if="!dataproduct?.notes">
                      <p><b>Catatan :</b></p>
                    </div>
                  </td>
                </tr> -->
              </table>
            </div>
            <div class="p-2 px-md-4 px-lg-5">
                <h5 class="text-right w-100 px-lg-5 d-block">
                    <b>Total Harga :  105000</b>
                </h5>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="profile"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <form class="form-regis" ref="registrasi">
            <div class="row">
                <div class="col-md mt-4">
                    <label for="bank">Nama Bank</label>
                    <input id="bank" type="text" class="form-control mt-1 radius" placeholder="Nama Bank">
                </div>
            </div>
            <div class="row">
                <div class="col-md mt-4">
                    <label for="nama">Atas Nama</label>
                    <input id="nama" type="text" class="form-control mt-1 radius" placeholder="Atas Nama">
                </div>
            </div>
            <div class="row">
                <div class="col-md mt-4">
                    <label for="rekening">No Rekening</label>
                    <input id="rekening" type="text" class="form-control mt-1 radius" placeholder="Nomor Rekening">
                </div>
            </div>
            <div class="row">
                <div class="col-md mt-4">
                    <label for="jumlah">Jumlah</label>
                    <input id="jumlah" type="text" class="form-control mt-1 radius" placeholder="Jumlah">
                </div>
            </div>
            <!-- <button class="btn btn-danger mt-3">Simpan</button> -->
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
::v-deep .modal-header button {
  background-color: #fff !important;
  border: 0px !important;
}
::v-deep #modal-loading .modal-content {
  background-color: transparent;
  border: 0px !important;
}
.border-bottom{
  border-bottom: 1px solid rgb(171 171 171) !important;
}
.nav-tabs li .nav-link{
  background-color: #992619 ;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.nav-tabs li .active{
  background-color:#eb2812 !important;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.float-right {
  float: right;
}
.bg-kuning {
  background-color: #f7d716;
  color: #fff !important;
}
.informationTable td {
  padding: 5px 2px;
  vertical-align: baseline;
}
/* .data-tabel, tr, td, th{
    border:0px !important;
} */
.user_label {
  width: 90px;
}
.card_info h5 {
  font-weight: 600;
}
.card_info h6 {
  font-weight: 600;
}
.data-ongkir {
  box-shadow: 10px 10px 19px #f5f5f5, -10px -10px 19px #efefef;
  border-radius: 15px;
}
.info_pengiriman p {
  margin-bottom: 0.5rem;
  line-height: 1.2;
}
.alamat_pengiriman {
  margin-bottom: 0px !important;
}
.diskon {
  display: flex;
}
.diskon p {
  color: #fff;
  font-size: 8px !important;
  padding: 3px;
  border-radius: 3px;
  background-color: red;
}
.diskon s {
  color: gray;
  font-size: 0.8rem !important;
  padding-left: 5px;
  text-decoration: line-through !important;
}
.product-data {
  overflow-x: auto;
}
.headcart {
  text-align: left;
  font-weight: 600;
  color: #000;
}

.product-cart {
  overflow-x: auto;
}

.table-product {
  position: relative;
  width: 100%;
}
.table-product tr {
  background-color: transparent !important;
}
.header-table::before {
  content: "";
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  width: 100%;
  height: 3.5rem;
  position: absolute;
}
.image {
  max-width: 50px;
  max-height: 50px;
  overflow: hidden;
  border-radius: 5px;
}
.image img {
  width: 100%;
}
.detail_product h6 {
  font-weight: 600;
}
.transaksi-footer-bottom {
  display: block;
  position: initial;
  bottom: 1.5rem;
  right: 2rem;
}
.confirmation-order-user {
  border-radius: 10px;
  box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.05),
    -2px -2px 10px rgba(0, 0, 0, 0.02);
}
.confirmation-order-ekspedisi {
  border-radius: 10px;
  box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.05),
    -2px -2px 10px rgba(0, 0, 0, 0.02);
}
.web-view {
  display: none;
}
.mobile-view {
  display: block;
}
.ringkasan h5 {
  font-weight: 600;
}
.card-ringkasan {
  border-radius: 10px;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
  padding: 1.75rem;
  text-align: right;
  display: flex;
  justify-content: end;
}

.table-ringkasan-belanja {
  width: 100%;
}

.table-ringkasan-belanja th {
  text-align: left;
}

.total-ringkasan-belanja th {
  border-top: 1px solid var(--primary-color);
  font-size: 1.25rem;
}

.total-ringkasan-belanja th:nth-child(2) {
  text-align: right;
}
.bg-datahidden {
  background-color: brown;
  color: #fff;
}
@media (max-width: 500px) {
  .text-header-detail {
    font-size: 0.8rem;
  }
  .confirmation-order-user p {
    font-size: 0.8rem !important;
  }
  .confirmation-order-user h5 {
    font-size: 0.9rem !important;
  }
  .confirmation-order-ekspedisi h5 {
    font-size: 0.9rem !important;
  }
  .confirmation-order-ekspedisi p {
    font-size: 0.8rem !important;
  }
  .container-produk {
    padding: 0px;
  }
  .header-table th {
    font-size: 0.8rem;
    padding-top: 1.7rem !important;
  }
  .detail_product h6 {
    font-size: 0.8rem;
  }
  .detail_product p {
    font-size: 0.8rem;
  }
  .tr-product td {
    min-width: 150px;
  }
  .dataproduk {
    min-width: 250px !important;
  }
  .tr-product td p {
    font-size: 0.8rem;
  }
  .ringkasan h5 {
    font-size: 1rem;
  }
  .card-ringkasan {
    padding: 0.7rem !important;
  }
  .card-ringkasan table {
    font-size: 0.8rem;
  }
  .total-ringkasan-belanja th {
    font-size: 0.9rem !important;
  }
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}
@media (min-width: 567) {
  .table-ringkasan-belanja {
    width: 70%;
  }
}

@media (min-width: 767px) {
  .text-header-detail {
    line-height: 2;
  }
  .transaksi-footer-bottom {
    position: absolute;
  }
  .tothar {
    max-width: 150px;
  }
  .view-table {
    display: table-footer-group;
    border-top: 1px solid #dee2e6 !important;
  }
  .view-div {
    display: none;
  }
  .header-product {
    display: block;
  }
  .table-ringkasan-belanja {
    width: 70%;
  }
}

@media (min-width: 1023px) {
  .web-view {
    display: flex;
    justify-content: space-evenly;
  }
  .mobile-view {
    display: none;
  }
}

@media (min-width: 1439px) {
  .table-ringkasan-belanja {
    width: 50%;
  }
}

@media (min-width: 2000px) {
}
</style>
