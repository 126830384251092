<template>
    <div class="datasection mt-5 pt-3 pt-md-5">
         <div class="container">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb" v-if="lang === 'ind'">
                    <li class="breadcrumb-item"><router-link to="/">Beranda</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">Hubungi Kami</li>
                </ol>
                <ol class="breadcrumb" v-else-if="lang === 'eng'">
                    <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
                </ol>
            </nav>
         </div>
        <h3 class="text-center" v-if="lang === 'ind'"><b>Hubungi Sales Kami</b></h3>
        <h3 class="text-center" v-else-if="lang === 'eng'"><b>Contact Our Sales</b></h3>
        <p class="text-center text-danger" v-if="lang === 'ind'"><b>Tim kami akan menjawab pertanyaan Anda dalam 24 jam</b></p>
        <p class="text-center text-danger" v-else-if="lang === 'eng'"><b>Our team will answer your questions within 24 hours</b></p>
        <div class="container">
            <div class="row">
                <div class="col-md-6  data-artpay">
                    <div class="h-100 p-2 p-md-4">
                        <form action="" @submit.prevent="HubungiKami()">
                            <div class="mb-3">
                                <input v-model="name" type="text"  id="name" class="form-control py-3" placeholder="Nama">
                                <span v-if="v$.name.$error" class="text-danger font-small pesan-error">{{v$.name.$errors[0].$message}}</span>
                            </div>
                            <div class="mb-3">
                                <input type="email" v-model="email"  id="email" class="form-control py-3" placeholder="Email">
                                <span v-if="v$.email.$error" class="text-danger font-small pesan-error">{{v$.email.$errors[0].$message}}</span>
                            </div>
                            <div class="mb-3">
                                <input type="tel" v-model="phone"  id="phone" class="form-control py-3" placeholder="No. Telepon">
                                <span v-if="v$.phone.$error" class="text-danger font-small pesan-error">{{v$.phone.$errors[0].$message}}</span>
                            </div>
                            <div class="mb-3">
                                <textarea v-model="message" class="form-control"  name="" id="message" cols="30" rows="10" placeholder="Pesan"></textarea>
                                <span v-if="v$.message.$error" class="text-danger font-small pesan-error">{{v$.message.$errors[0].$message}}</span>
                            </div>
                            <button type="submit" class="btn btn-danger w-100">Submit</button>
                        </form>
                        
                    </div>
                </div>
                <div class="col-md-6 data-artpay margin-top-data-artpay">
                    <div class=" h-100 p-2 p-md-4 maps">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3944.1462613786375!2d115.2305317!3d-8.677637899999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd240f733681217%3A0xa7aa895898de5734!2sJl.%20Tukad%20Yeh%20Aya%20No.46%2C%20Panjer%2C%20Denpasar%20Selatan%2C%20Kota%20Denpasar%2C%20Bali%2080234!5e0!3m2!1sid!2sid!4v1676557343670!5m2!1sid!2sid"  style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="container mt-5 pb-5">
            <div class="container card b-radius-card p-3 p-md-4">
            <form class="form-regis" @submit.prevent="HubungiKami"  ref="HubungiKami">
                <div class="row">
                    <div class="col-md mt-4">
                        <label for="name">Nama</label>
                        <input id="name" type="text" class="form-control mt-1 radius" placeholder="Nama" v-model="name" :class="[v$.name.$error ? 'is-invalid' : '']">
                        <span v-if="v$.name.$error" class="text-danger font-small pesan-error">{{v$.name.$errors[0].$message}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md mt-4">
                        <label for="email">Alamat Email</label>
                        <input id="email" type="email" class="form-control mt-1 radius" placeholder="Alamat Email" v-model="email" :class="[v$.email.$error ? 'is-invalid' : '']">
                        <span v-if="v$.email.$error" class="text-danger font-small pesan-error">{{v$.email.$errors[0].$message}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md mt-4">
                        <label for="perusahaan">Perusahaan / Website</label>
                        <input id="perusahaan" type="text" class="form-control mt-1 radius" placeholder="Perusahaan / Website" v-model="perusahaan" :class="[v$.perusahaan.$error ? 'is-invalid' : '']">
                        <span v-if="v$.perusahaan.$error" class="text-danger font-small pesan-error">{{v$.perusahaan.$errors[0].$message}}</span>
                    </div>
                    <div class="col-md mt-4">
                        <label for="Telepon">Telepon</label>
                        <input id="Telepon" type="text" class="form-control mt-1 radius" placeholder="Telepon" v-model="telepon" :class="[v$.telepon.$error ? 'is-invalid' : '']">
                        <span v-if="v$.telepon.$error" class="text-danger font-small pesan-error">{{v$.telepon.$errors[0].$message}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md mt-4">
                        <label for="karyawan">Jumlah karyawan perusahaan</label>
                        <input id="karyawan" type="text" class="form-control mt-1 radius" placeholder="Jumlah karyawan perusahaan" v-model="karyawan" :class="[v$.karyawan.$error ? 'is-invalid' : '']">
                        <span v-if="v$.karyawan.$error" class="text-danger font-small pesan-error">{{v$.karyawan.$errors[0].$message}}</span>
                    </div>
                    <div class="col-md mt-4">
                        <label for="industri">Jenis Industri</label>
                        <input id="industri" type="text" class="form-control mt-1 radius" placeholder="Jenis Industri" v-model="industri" :class="[v$.industri.$error ? 'is-invalid' : '']">
                        <span v-if="v$.industri.$error" class="text-danger font-small pesan-error">{{v$.industri.$errors[0].$message}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md mt-4">
                        <label for="estimasi">Estimasi transaksi per bulan</label>
                        <input id="estimasi" type="text" class="form-control mt-1 radius" placeholder="Estimasi transaksi per bulan" v-model="estimasi" :class="[v$.estimasi.$error ? 'is-invalid' : '']">
                        <span v-if="v$.estimasi.$error" class="text-danger font-small pesan-error">{{v$.estimasi.$errors[0].$message}}</span>
                    </div>
                    <div class="col-md mt-4">
                        <label for="omset">Omset perusahaan per tahun</label>
                        <input id="omset" type="text" class="form-control mt-1 radius" placeholder="Omset perusahaan per tahun" v-model="omset" :class="[v$.omset.$error ? 'is-invalid' : '']">
                        <span v-if="v$.perusahaan.$error" class="text-danger font-small pesan-error">{{v$.perusahaan.$errors[0].$message}}</span>
                    </div>
                </div>
                <div class="mt-3">
                    <label for="pertanyaan">Apakah Anda memiliki pertanyaan spesifik untuk kami?</label>
                    <textarea name="pertanyaan" id="pertanyaan" rows="3" class="form-control w-100 mt-1 border-lightdata" v-model="pertanyaan" :class="[v$.pertanyaan.$error ? 'is-invalid' : '']"
                    ></textarea>
                    <span v-if="v$.pertanyaan.$error" class="text-danger font-small pesan-error">{{v$.pertanyaan.$errors[0].$message}}</span>

                </div>
                <button class="btn btn-danger btn-kirim px-4 py-2 mt-3 mt-lg-5">Kirim</button>
            </form>
        </div>
        </div> -->


    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required,helpers, email, numeric  } from '@vuelidate/validators'
export default {
    data(){
        return{
            name:'',
            email:'',
            message:'',
            phone:'',
        }
    },
    setup () {
        return { v$: useVuelidate() }
    },
    computed:{
        lang: function() {
            return this.$store.state.language;
        },
    },
    validations() {
        return {
            name: {
                required: helpers.withMessage("Nama tidak boleh kosong", required)
            },                         
            email: {
                required: helpers.withMessage("Alamat Email tidak boleh kosong", required),
                email: helpers.withMessage("Format email salah", email)
            },                         
            message: {
                required: helpers.withMessage("Pesan tidak boleh kosong", required)
            },                         
            phone: {
                required: helpers.withMessage("Telepon tidak boleh kosong", required),
                numeric: helpers.withMessage("Pastikan data yang diinput berupa angka", numeric)
            },                         
            // karyawan: {
            //     required: helpers.withMessage("Jumlah karyawan perusahaan tidak boleh kosong", required)
            // },                         
            // industri: {
            //     required: helpers.withMessage("Jenis Industri tidak boleh kosong", required)
            // },                         
            // estimasi: {
            //     required: helpers.withMessage("Estimasi transaksi per bulan tidak boleh kosong", required)
            // },                         
            // omset: {
            //     required: helpers.withMessage("Omset perusahaan per tahun tidak boleh kosong", required)
            // },                         
            // pertanyaan: {
            //     required: helpers.withMessage("pertanyaan tidak boleh kosong", required)
            // },                         
            
        }
    },
    methods:{
         HubungiKami() {

            this.v$.$validate()
            if ( !this.v$.$error) {
                window.open('https://api.whatsapp.com/send/?phone=628113137888&text=Nama='+this.name+'%0D%0APhone='+this.phone+'%0D%0AEmail='+this.email+'%0D%0APesan='+this.message+'&type=phone_number&app_absent=0');
            }
        },
    }
}
</script>
<style scoped>
.p-card-header{
    padding: 0 !important;
}
.breadcrumb-item{
    font-size:1rem
}
.datasection{
    background-image: url('../../assets/img/bgdata.png');
    background-repeat: repeat;
}
.data-artpay figure{
    width: 100px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10;
}
.data-artpay p{
    position: relative;
    z-index: 100;
}
.data-artpay figure img{
    width: 100%;
}
.listcard{
    background-color: #fff;
}
.databank figure{
    width: 300px;
}
.databank figure img{
    width: 100%;
}
.btn-accordion{
    text-align: left !important;
}
.card-header button{
    padding: 0.75rem 1.25rem !important;
    text-decoration: none !important;
    color: #fff !important;
    background-color: #000;
}
.btn-kirim{
    display: block;
    margin: 0 auto;
}
.breadcrumb{
    background-color: transparent !important;
    justify-content: end;
    font-size: 10px;
}
.breadcrumb a{
    color: #000 !important;
    font-weight: 700;
    text-decoration: none !important;
    font-size: 1rem;
}
.breadcrumb .active{
    font-weight: 700;
}
@media (max-width: 500px) {
    .margin-top-data-artpay{
        margin-top: 2rem;
    }
    .maps iframe{
        width: 100%;
        height: 70vh;
    }
    .breadcrumb .active{
        font-weight: 700;
    }
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
    .maps iframe{
        width: 100%;
        height: 300px;
    }
    .breadcrumb{
        font-size: 1rem;
    }
}

@media (min-width: 1023px) {
    .databank figure{
       width: 400px;
    }
    .maps iframe{
        width: 100%;
        height: 300px;
    }
    
}

@media (min-width: 1439px) {
    .databank figure{
       width: 500px;
    }
}

@media (min-width: 2000px) {
}
</style>