<template>
    <div class="footer">
    <div class="container  pt-5">
        <div class="row pt-3">
            <div class="col-md-3 mt-m">
                <img src="../assets/img/ArtPay_samping_msaster.png" class="logobrand" alt="">
                <h4 class="mb-3 mt-md-3 social-media mt-mobile-sosmed" v-if="lang ==='ind'">IKUTI SOSIAL MEDIA KAMI</h4>
                <h4 class="mb-3 mt-md-3 social-media mt-mobile-sosmed" v-if="lang ==='eng'">FOLLOW US ON SOCIAL MEDIA</h4>
                <div class="sosmed mb-2">
                    <!-- <a href="" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ;msFilter:;"><path d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248 4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008 3.004 3.004 0 0 1 0 6.008z"></path><circle cx="16.806" cy="7.207" r="1.078"></circle><path d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42 4.6 4.6 0 0 0-2.633 2.632 6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419 4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688 2.987 2.987 0 0 1-1.712 1.711 4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311 2.985 2.985 0 0 1-1.719-1.711 5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654 0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311 2.991 2.991 0 0 1 1.712 1.712 5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011z"></path></svg>
                    </a> -->
                    <a href="https://t.me/artpay888" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ;msFilter:;"><path d="m20.665 3.717-17.73 6.837c-1.21.486-1.203 1.161-.222 1.462l4.552 1.42 10.532-6.645c.498-.303.953-.14.579.192l-8.533 7.701h-.002l.002.001-.314 4.692c.46 0 .663-.211.921-.46l2.211-2.15 4.599 3.397c.848.467 1.457.227 1.668-.785l3.019-14.228c.309-1.239-.473-1.8-1.282-1.434z"></path></svg>
                    </a>
                    <a href="https://www.facebook.com/artpay888" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" style="transform: ;msFilter:;"><path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path></svg>
                    </a>
                </div> 
            </div>
            <div class="col-md-2 mt-mobile" v-if="lang === 'ind'">
                <h4 class="mb-3 header">PRODUK</h4>
                <router-link to="/pembayaran-online">Pembayaran Online</router-link><br>
                <router-link to="/dompet-digital">Dompet Digital</router-link>
            </div>
            <div class="col-md-2 mt-mobile" v-else-if="lang === 'eng'">
                <h4 class="mb-3 header">PRODUCT</h4>
                <router-link to="/pembayaran-online">Online Payments</router-link><br>
                <router-link to="/dompet-digital">Digital Wallet</router-link>
            </div>
            <div class="col-md-3 mt-mobile" v-if="lang === 'ind'">
                <h4 class="mb-3 header">PERUSAHAAN</h4>
                <router-link to="tentang-kami">Tentang Kami</router-link><br>
                <router-link to="kebijakan-privasi">Kebijakan Privasi</router-link>
            </div>
            <div class="col-md-3 mt-mobile" v-else-if="lang === 'eng'">
                <h4 class="mb-3 header">COMPANY</h4>
                <router-link to="tentang-kami">About Us</router-link><br>
                <router-link to="kebijakan-privasi">Privacy Policy</router-link>
            </div>
            <div class="col-md-4 mt-mobile">
                <h4 class="mb-3 header">LOCATION ARTPAY</h4>
                <p>Jalan Tukad Yeh Aya No. 46, Panjer, Denpasar Selatan, Kota Denpasar, Bali, Indonesia</p>
                <p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(255, 255, 255, 0.5);transform: ;msFilter:;"><path d="M16.57 22a2 2 0 0 0 1.43-.59l2.71-2.71a1 1 0 0 0 0-1.41l-4-4a1 1 0 0 0-1.41 0l-1.6 1.59a7.55 7.55 0 0 1-3-1.59 7.62 7.62 0 0 1-1.59-3l1.59-1.6a1 1 0 0 0 0-1.41l-4-4a1 1 0 0 0-1.41 0L2.59 6A2 2 0 0 0 2 7.43 15.28 15.28 0 0 0 6.3 17.7 15.28 15.28 0 0 0 16.57 22zM6 5.41 8.59 8 7.3 9.29a1 1 0 0 0-.3.91 10.12 10.12 0 0 0 2.3 4.5 10.08 10.08 0 0 0 4.5 2.3 1 1 0 0 0 .91-.27L16 15.41 18.59 18l-2 2a13.28 13.28 0 0 1-8.87-3.71A13.28 13.28 0 0 1 4 7.41zM20 11h2a8.81 8.81 0 0 0-9-9v2a6.77 6.77 0 0 1 7 7z"></path><path d="M13 8c2.1 0 3 .9 3 3h2c0-3.22-1.78-5-5-5z"></path></svg>
                    08113137888
                </p>
                <p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(255, 255, 255, 0.5);transform: ;msFilter:;"><path d="M20 4H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm0 2v.511l-8 6.223-8-6.222V6h16zM4 18V9.044l7.386 5.745a.994.994 0 0 0 1.228 0L20 9.044 20.002 18H4z"></path></svg>
                    info@artpay.co.id
                </p>
            </div>
            <!-- <div class="col-md-3"> -->
                <!-- <h4 class="mb-3 mt-md-3 mt-lg-5">Follow Us</h4>
                <div class="sosmed mb-2">
                    <a :href="(setting?.instagram?.substring(0,4) === 'http' ? '' : 'https://')+setting?.instagram" v-if="setting?.instagram !== '' || setting?.instagram !== null" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ;msFilter:;"><path d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248 4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008 3.004 3.004 0 0 1 0 6.008z"></path><circle cx="16.806" cy="7.207" r="1.078"></circle><path d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42 4.6 4.6 0 0 0-2.633 2.632 6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419 4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688 2.987 2.987 0 0 1-1.712 1.711 4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311 2.985 2.985 0 0 1-1.719-1.711 5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654 0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311 2.991 2.991 0 0 1 1.712 1.712 5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011z"></path></svg>
                    </a>
                    <a :href="(setting?.tiktok?.substring(0,4) === 'http' ? '' : 'https://')+setting?.tiktok" v-if="setting?.tiktok !== '' || setting?.tiktok !== null" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" style="transform: ;msFilter:;"><path d="M19.59 6.69a4.83 4.83 0 0 1-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 0 1-5.2 1.74 2.89 2.89 0 0 1 2.31-4.64 2.93 2.93 0 0 1 .88.13V9.4a6.84 6.84 0 0 0-1-.05A6.33 6.33 0 0 0 5 20.1a6.34 6.34 0 0 0 10.86-4.43v-7a8.16 8.16 0 0 0 4.77 1.52v-3.4a4.85 4.85 0 0 1-1-.1z"></path></svg>
                    </a>
                    <a :href="(setting?.facebook?.substring(0,4) === 'http' ? '' : 'https://')+setting?.facebook" v-if="setting?.facebook !== '' || setting?.facebook !== null" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" style="transform: ;msFilter:;"><path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path></svg>
                    </a>
                    <a :href="(setting?.youtube?.substring(0,4) === 'http' ? '' : 'https://')+setting?.youtube" v-if="setting?.youtube !== '' || setting?.youtube !== null" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ;msFilter:;"><path d="M21.593 7.203a2.506 2.506 0 0 0-1.762-1.766C18.265 5.007 12 5 12 5s-6.264-.007-7.831.404a2.56 2.56 0 0 0-1.766 1.778c-.413 1.566-.417 4.814-.417 4.814s-.004 3.264.406 4.814c.23.857.905 1.534 1.763 1.765 1.582.43 7.83.437 7.83.437s6.265.007 7.831-.403a2.515 2.515 0 0 0 1.767-1.763c.414-1.565.417-4.812.417-4.812s.02-3.265-.407-4.831zM9.996 15.005l.005-6 5.207 3.005-5.212 2.995z"></path></svg>
                    </a>
                </div> -->
                <!-- <h4 class="mb-3">Sign up for our Newsletter</h4>
                <div class="email">
                    <div class="px-1">
                        <b-form-input
                        id="input-1"
                        type="email"
                        placeholder="Enter email"
                        ></b-form-input>
                    </div>
                        <b-button type="submit" variant="dark">Submit</b-button>
                </div> -->
            <!-- </div> -->
            <!-- <div class="col-md-6">
                <figure class="image-footer">
                    <img src="../assets/img/footeranjing.svg" alt="">
                </figure>
            </div> -->
        </div>
        <div class="footer-bottom">
            <router-link to="/" class="text-center">Artpay.co.id 2023</router-link>
        </div>
    </div>
    </div>
</template>
<script>
export default {
     computed:{
        lang: function() {
            return this.$store.state.language;
        },
    },
}
</script>

<style scoped>

.logobrand{
        width: 100px;
    }
.footer{
    background-color: #000!important;
    color: #fff;
}
.footer .header{
    font-weight: 600;
    color: #fff;
    text-align: left;
    font-size: 1.15rem;
}
.footer p{
    text-align: left;
    color: rgba(255, 255, 255, 0.5);
    font-size: 1rem;
    margin-bottom: 0.5rem !important;
}
.footer a{
    text-decoration: none;
    text-align: left;
    color: rgba(255, 255, 255, 0.5);
    font-size: 1rem;
    line-height: 1;
}
.footer .header-footer{
    height: 1px;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0px -5px 50px 1px rgba(0, 0, 0, 0.3);
}
.sosmed{
    display: flex;
}
.sosmed a{
    background-color: #343a40;
    border-radius: 50%;
    padding: 7px;
    margin-left: 5px;
    margin-right: 5px;
}
.sosmed a:hover{
    background-color: #51555a;
}
.sosmed svg{
    fill: #fff;
    width: 24px;
    height: 24px;
}
.email{
    display: flex;
}
.footer-bottom{
    border-top: 1px solid #fff;
    padding-top: 10px;
    padding-bottom: 5px;
}
.footer-bottom a{
    text-align: center;
    width: 100%;
    display: block;
}
.image-footer{
    max-height: 250px;
    width: 250px;
    margin: 0 auto;
}
.image-footer img{
    width: 100%;
    height: 100%;
}
.social-media{
        font-weight: 600;
    color: #fff;
    text-align: left;
    font-size: 1rem;
}
@media (max-width: 500px) {
    .mt-mobile{
        margin-top: 2rem;
    }
    .mt-mobile-sosmed{
        margin-top: 1rem;
    }
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
.logobrand{
    width: 125px;
}
.footer{
margin-bottom: 0rem;
}
.footer .header{
    font-size: 1rem;
}
.footer p{
    font-size: 0.6rem;
}
.footer a{
    font-size: 0.8rem;
    line-height: 1.5;
}
.image-footer{
    width: 200px;
}
.social-media{
    font-size: 0.7rem;
}
}

@media (min-width: 1023px) {
.logobrand{
    width: 160px;
}
    .footer .header{
    font-size: 1.1rem;
}
.footer p{
    font-size: 1rem;
}
.footer a{
    font-size: 1rem;
    line-height: 2;
}
.image-footer{
    width: 300px;
}
.sosmed a{
    padding: 9px 10px;
}
.sosmed svg{
    width: 28px;
    height: 28px;
}
.social-media{
    font-size: 0.9rem;
}
}

@media (min-width: 1439px) {
    .social-media{
    font-size: 1rem;
}

}

@media (min-width: 2000px) {
}
</style>