<template>
  <div>
    <div class="datasection mt-5 pt-3 pt-md-5 pb-5" v-if="lang === 'ind'">
    <div class="container">
        <nav aria-label="breadcrumb" >
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/">Beranda</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Tentang Kami</li>
            </ol>
        </nav>
    </div>
    <h3 class="text-center"><b>Tentang Kami</b></h3>
    <p class="p-tentang text-center">
      ArTpay adalah perusahaan teknologi yang bekerja untuk mendistribusikan semua jenis pembayaran dengan kinerja layanan teruji dan terbukti.
    </p><br>
    <p class="p-tentang text-justify">ArTpay menawarkan fasilitas pembayaran dari banyak bank dan sistem pembayaran
online yang tersedia di Indonesia. Dengan ArTpay Anda dapat menerima
pembayaran dari konsumen menggunakan Qris, Ovo, Dana, Gopay, Shopeepay atau
kartu debit dan kredit dari beberapa bank tanpa harus membuka akun atau rekening
pembayaran online di bank tersebut. ArTpay hadir untuk mempermudah bisnis anda,
sehingga tidak perlu khawatir lagi menerima transaksi cashless.</p><br>
<p class="p-tentang text-justify">ArTpay memiliki lini produk terlengkap dan jaringan kemitraan yang luas, ArTpay
berkomitmen mendorong kinerja bisnis dan memaksimalkan potensi bisnis mitra
kami, mari hasilkan keuntungan Optimal bersama ArTpay</p>
    <!-- <div class="container mt-5">
      <div class="grid-container">
        <div class="column">

          <img
            src="../../assets/img/tentangkami1.jpg"
          />

          <img
            src="../../assets/img/tentangkami3.jpg"
          />
        </div>

        <div class="column">
          <img
            src="../../assets/img/tentangkami2.jpg"
          />

          <img
            src="../../assets/img/tentangkami4.jpg"
          />
        </div>

      </div>
    </div> -->
  </div>
  <div class="datasection mt-5 pt-3 pt-md-5 pb-5" v-else-if="lang === 'eng'">
    <div class="container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">About Us</li>
            </ol>
        </nav>
    </div>
    <h3 class="text-center"><b>About Us</b></h3>
    <p class="p-tentang text-center">
      Welcome to ArTpay, your trusted technology partner dedicated to simplifying
all types of payments with proven and reliable service performance.
    </p><br>
    <p class="p-tentang text-justify">At ArTpay, we offer a wide range of payment facilities from various banks and
online payment systems available in Indonesia. With ArTpay, you can
seamlessly accept payments from customers using Qris, Ovo, Dana, Gopay,
Shopeepay, or debit and credit cards from multiple banks, all without the
hassle of opening online payment accounts with those banks. ArTpay is here
to make your business operations smoother, allowing you to embrace
cashless transactions worry-free.</p><br>
<p class="p-tentang text-justify">We take pride in our comprehensive product lineup and extensive network of
partners. ArTpay is committed to driving business performance and
maximizing the potential of our partners. Let's work together to achieve
optimal profits with ArTpay by your side.</p>
    <!-- <div class="container mt-5">
      <div class="grid-container">
        <div class="column">

          <img
            src="../../assets/img/tentangkami1.jpg"
          />

          <img
            src="../../assets/img/tentangkami3.jpg"
          />
        </div>

        <div class="column">
          <img
            src="../../assets/img/tentangkami2.jpg"
          />

          <img
            src="../../assets/img/tentangkami4.jpg"
          />
        </div>

      </div>
    </div> -->
  </div>
  </div>
</template>
<script>
export default {
    computed:{
        lang: function() {
            return this.$store.state.language;
        },
    },
}
</script>
<style scoped>
.p-card-header {
  padding: 0 !important;
}
.breadcrumb-item{
    font-size:1rem
}
.datasection {
  background-image: url("../../assets/img/bgdata.png");
  background-repeat: repeat;
}
.p-tentang {
  display: block;
  text-align: center;
  margin: 0 auto;
}
.grid-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

.column {
  flex: 20%;
  max-width: 50%;
  padding: 5px 8px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}
.breadcrumb{
    background-color: transparent !important;
    justify-content: end;
    font-size: 10px;
}
.breadcrumb a{
    color: #000 !important;
    font-weight: 700;
    text-decoration: none !important;
    font-size: 1rem;
}
.breadcrumb .active{
    font-weight: 600;
}
@media (max-width: 1200px) {
  .column {
    flex: 40%;
    flex-wrap: wrap;
    max-width: 50%;
  }
}

@media (max-width: 600px) {
  .column {
    flex: 50%;
    max-width: 100%;
  }
}
@media (max-width: 500px) {
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
  .breadcrumb{
      font-size: 1rem;
  }
}

@media (min-width: 1023px) {
  .p-tentang {
    width: 75%;
    display: block;
    margin: 0 auto;
  }
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>
