<template>
    <section class="bg-black">
        
        <div class="container section-header pb-md-5">
          <nav aria-label="breadcrumb" >
                <ol class="breadcrumb" v-if="lang === 'ind'">
                    <li class="breadcrumb-item"><router-link to="/">Beranda</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">Biaya</li>
                </ol>
                <ol class="breadcrumb" v-else-if="lang === 'eng'">
                    <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">Pricing</li>
                </ol>
            </nav>
            <div class="row ps-md-6 pe-md-5">
                <div class="col-7 col-md-6 ps-md-5 text-white text-md-start mt-md-4" data-aos="fade-right" data-aos-delay="400">
                    <div class="pt-md-3 pt-lg-5 data-left" v-if="lang === 'ind'">
                        <h4 class="pt-lg-5"><b>Harga Transparan, Jelas Dan Kompetitif</b></h4>
                        <p class=" lh-base">  Artpay menyediakan layanan Payment Gateway tanpa biaya tersembunyi. Harga kami jelas, transparan dan kompetitif</p>
                        <router-link to="/hubungi-kami" class="btn btn-mulai btn-danger p-md-3 ps-5 pe-5 mb-4">
                            MULAI SEKARANG
                        </router-link>
                    </div>
                    <div class="pt-md-3 pt-lg-5 data-left" v-else-if="lang === 'eng'">
                        <h4 class="pt-lg-5"><b>Transparent, Clear, and Competitive Pricing.</b></h4>
                        <p class=" lh-base">ArtPay provides Payment Gateway services with no hidden fees. Our pricing
is clear, transparent, and competitive.</p>
                        <router-link to="/hubungi-kami" class="btn btn-mulai btn-danger p-md-3 ps-5 pe-5 mb-4">
                            START NOW
                        </router-link>
                    </div>
                </div>
                <div class="col-5 col-md-6 pb-md-5" data-aos="fade-left" data-aos-delay="400">
                    <div class="dataimg">
                        <img class="imgblock" src="../../assets/img/Price.svg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    computed:{
        lang: function() {
            return this.$store.state.language;
        },
    },
}
</script>
<style scoped>
.dataimg{
    text-align: center;
}
.bg-black{
    background-color: #000 !important;
}
.data-left h4{
    font-size: 0.75rem ;
}
.data-left p,.data-left a{
    font-size: 0.5rem ;
}
.breadcrumb{
    background-color: transparent !important;
    justify-content: end;
    font-size: 10px;
}
.breadcrumb .active{
    font-weight: 600;
}
.breadcrumb a{
    color: #fff !important;
    font-weight: 700;
    text-decoration: none !important;
}
.section-header{
    padding-top: 55px;
    overflow-x: hidden
}
@media (max-width:500px) {
    .imgblock{
        width: 100%;
    }
}
@media (min-width: 350px) {
    .data-left h4{
        font-size: 0.9rem;
    }
    .data-left p, .data-left a{
        font-size: 0.7rem;
    }
}
@media (min-width: 400px) {
    .data-left h4{
        font-size: 1rem;
    }
    .data-left p, .data-left a{
        font-size: 0.8rem ;
    }
}
@media (min-width: 700px) {
    .data-left h4{
        font-size: 1.75rem;
    }
    .data-left p, .data-left a{
        font-size: 1rem ;
    }
    .section-header{
        padding-top: 100px;
        overflow-x: hidden
    }
    .breadcrumb{
        font-size: 1rem;
    }
    .imgblock{
        height: 330px;
    }
    .btn-mulai{
        margin-top: 1rem;
        padding: 0.5rem 0.6rem !important;
    }
}
@media (min-width: 900px) {
    .imgblock{
        height: 400px;
    }
}
</style>