<template>
  <div class="profile section">
    <Navbar />
    <div class="header-nav"></div>
    <div class="profile-container container">
      <!-- <div class="d-flex justify-content-end">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Beranda</a></li>
                <li class="breadcrumb-item active" aria-current="page">Beranda</li>
              </ol>
            </nav>
          </div> -->

      <div class="profile-data row">
        <div class="col-lg-3">
          <ul class="list-group-custom" id="profilList">
            <li>
              <router-link
                to="/profile/kredensial"
                :class="[datalink == '/profile/kredensial' ? 'list-active' : '']"
                class="list-group-custom-item dashboard"
              >
                <img src="../../assets/img/log_in.png" alt="">
                <span class="pl-3">Kredensial</span>
              </router-link>
            </li>
            <li>
              <router-link
                to="/profile/transaksi"
                :class="[datalink == '/profile/transaksi' || datalink == '/profile/detail-transaksi' ? 'list-active' : '']"
                class="list-group-custom-item mt-1 form"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="transform: ;msFilter:;"><path d="m15 12 5-4-5-4v2.999H2v2h13zm7 3H9v-3l-5 4 5 4v-3h13z"></path></svg>
                <span class="pl-3">Transaksi</span>
              </router-link>
            </li>
            <li>
              <router-link
                to="/profile/pengaturan"
                class="list-group-custom-item mt-1"
                :class="[datalink == '/profile/pengaturan' ? 'list-active' : '']"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="transform: ;msFilter:;"><path d="m2.344 15.271 2 3.46a1 1 0 0 0 1.366.365l1.396-.806c.58.457 1.221.832 1.895 1.112V21a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-1.598a8.094 8.094 0 0 0 1.895-1.112l1.396.806c.477.275 1.091.11 1.366-.365l2-3.46a1.004 1.004 0 0 0-.365-1.366l-1.372-.793a7.683 7.683 0 0 0-.002-2.224l1.372-.793c.476-.275.641-.89.365-1.366l-2-3.46a1 1 0 0 0-1.366-.365l-1.396.806A8.034 8.034 0 0 0 15 4.598V3a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v1.598A8.094 8.094 0 0 0 7.105 5.71L5.71 4.904a.999.999 0 0 0-1.366.365l-2 3.46a1.004 1.004 0 0 0 .365 1.366l1.372.793a7.683 7.683 0 0 0 0 2.224l-1.372.793c-.476.275-.641.89-.365 1.366zM12 8c2.206 0 4 1.794 4 4s-1.794 4-4 4-4-1.794-4-4 1.794-4 4-4z"></path></svg>
                <span class="pl-3">Pengaturan</span>
              </router-link>
            </li>
            <li>
              <a class="list-group-custom-item mt-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-3"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style="transform: ; msfilter: "
                >
                  <path d="M16 13v-2H7V8l-5 4 5 4v-3z"></path>
                  <path
                    d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2z"
                  ></path>
                </svg>
                Keluar
              </a>
            </li>
          </ul>
        </div>
      <b-modal id="modal-loading" centered hide-footer hide-header>
          <div class="text-center">
          <b-spinner variant="light"></b-spinner>
          </div>
      </b-modal>

        <div class="col-lg-9 h-100 mt-5 mb-5 mt-md-2 mb-md-5">
            <div class="data-dashboard px-2 py-3 p-md-4 h-100">
                <router-view></router-view>
            </div>
          <!-- <DashboardUser v-if="profilContent === 'DashboardUser'"></DashboardUser>
              <FormProfile v-else-if="profilContent === 'form'"></FormProfile>
              <complain-data v-else-if="profilContent === 'komplain'"></complain-data>
              <add-complain v-else-if="profilContent === 'addkomplain'"></add-complain> -->
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/NavbarFe.vue";
import Footer from "@/components/FooterFe.vue";
// import DashboardUser from '../../components/DashboardUser.vue'
// import FormProfile from '../../components/FormProfile.vue'
// import ComplainData from '../../components/ComplainUser/ComplainData.vue'
// import AddComplain from '../../components/ComplainUser/AddComplain.vue'

export default {
  name: "profile-component",
  data() {
    return {
      profilContent: "DashboardUser",
      datalink:'',
    };
  },
  mounted(){
        this.checklink()
    },
    watch:{
        '$route.query': {
        handler: function() {
            this.checklink()
        },
        deep: true,
        immediate: true,
        }
    },
    methods:{
      checklink(){
        var link = this.$router.currentRoute._value.path
          this.datalink = link.toLowerCase()
      },
    },
  components: {
    Navbar,
    Footer,
    // FormProfile,
    // ComplainData,
    // AddComplain
  },
};
</script>
<style scoped>
::v-deep #modal-loading .modal-content{
  background-color: transparent;
  border: 0px !important;
}
.list-active{
  background-color:  #4A4A4A !important;
  border-radius: 7px !important;
  border-bottom: 1px solid #4A4A4A !important;
}
.profile{
    background-image: url('../../assets/img/bgdata.png');
}
.mr-3 {
  margin-right: 0.8rem;
}
.header-nav {
  height: 4rem;
}
.profile-data {
  padding-right: 0px;
  padding-left: 0px;
  margin: 0px !important;
}
.profile-content {
  padding-right: 0px;
  padding-left: 0px;
}

.profile-content-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.list-group-custom {
background-color: #000;
  padding: 1rem;
  list-style-type: none;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
}

.list-group-custom-item {
  color: #fff;
  fill: #fff;
  padding: 0.825rem 0.5rem;
  width: 100%;
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  text-decoration: none;
  cursor: pointer;
}

.list-group-custom-item.active,
.list-group-custom-item:hover {
  background-color:  #4A4A4A;
  border-radius: 7px;
  border-bottom: 1px solid #4A4A4A !important;
}
.data-dashboard{
    background-color: #fff;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
    
}
@media (max-width: 400px) {
  .profile-container,.profile-container .col-lg-3, .profile-container .col-lg-9 {
    padding: 0px;
  }
}
@media (min-width: 319px) {
}
@media (min-width: 374px) {
}
@media (min-width: 424px) {
}
@media (min-width: 576px) {
  .profile-content {
    width: 80%;
  }

  .profile-content-container {
    flex-direction: row;
    column-gap: 1rem;
  }
}
@media (min-width: 767px) {
  .header-nav {
    height: 9rem;
  }
  
  
}
@media (max-width: 800px) {
  .profile-data{
    margin-bottom: 2rem !important;
  }
  
}
@media (min-width: 1023px) {
  .header-nav {
    height: 2rem;
  }
  .profile-data{
    min-height: 65vh !important;
  }
  .profile-data .col-lg-3{
    padding-top: 4rem;
    background-color: #000 !important;
    padding-bottom: 2rem;
  }
  .profile-data .col-lg-9{
padding-top: 3.5rem;
  }
  .data-dashboard{
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .profile-data {
    padding-right: (1, 5rem, 0.75rem);
    padding-left: (1, 5rem, 0.75rem);
    margin: 0px !important;
  }
  .profile-content {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
@media (min-width: 1100px) {
}
@media (min-width: 1200px) {
}
@media (min-width: 1439px) {
}
@media (min-width: 1500px) {
}
@media (min-width: 2000px) {
}
</style>
