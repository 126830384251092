<template>
    <div>
        <section class="bg-black" >
        
        <div class="container" style="padding-top: 100px;overflow-x: hidden">
          
            <div class="row ps-md-5 pe-md-5">
                <div v-if="lang === 'ind'" class="col-7 col-md-5 ps-md-5 text-white text-md-start mt-md-4" data-aos="fade-right" data-aos-delay="400">
                    <div class="mt-lg-5 pt-md-5 data-left">
                        <h3 class=""><b><span class="bold">Art<span class="text-danger">P</span>ay</span> Smart Payment</b></h3>
                        <h4 class="lh-base">  Bersama Artpay, permudah transaksi semua bisnis anda.</h4>
                        <router-link to="/hubungi-kami" class="btn btn-mulai btn-danger p-md-3 ps-5 pe-5 mb-4">
                            MULAI SEKARANG
                        </router-link>
                    </div>
                </div>
                <div  v-else-if="lang === 'eng'" class="col-7 col-md-5 ps-md-5 text-white text-md-start mt-md-4" data-aos="fade-right" data-aos-delay="400">
                    <div class="mt-lg-5 pt-md-5 data-left">
                        <h3 class=""><b><span class="bold">Art<span class="text-danger">P</span>ay</span> Smart Payment</b></h3>
                        <h4 class="lh-base">  Simplify all your business transactions with ArtPay.</h4>
                        <router-link to="/hubungi-kami" class="btn btn-mulai btn-danger p-md-3 ps-5 pe-5 mb-4">
                            START NOW
                        </router-link>
                    </div>
                </div>
                <div class="col-5 col-md-7 pb-md-5" data-aos="fade-left" data-aos-delay="400">
                    <img src="../../assets/img/dataheader.png" class="w-100" alt="">
                </div>
            </div>
        </div>
    </section>
    </div>
</template>
<script>
export default {
    computed:{
        lang: function() {
            return this.$store.state.language;
        },
    },
}
</script>
<style scoped>
.bg-black{
    background-color: #000 !important;
}
.data-left h3{
    font-size: 0.9rem ;
}
.data-left h4{
    font-size: 0.8rem;
}
.data-left p,.data-left a{
    font-size: 0.5rem ;
}
@media (min-width: 350px) {
    .data-left h3{
        font-size: 0.9rem;
    }
    .data-left h4{
    font-size: 0.8rem;
}
    .data-left p, .data-left a{
        font-size: 0.7rem;
    }
}
@media (min-width: 400px) {
    .data-left h3{
        font-size: 1rem;
    }
    .data-left h4{
        font-size: 0.9rem;
    }
    .data-left p, .data-left a{
        font-size: 0.8rem ;
    }
}
@media (min-width: 700px) {
    .data-left h3{
        font-size: 1.75rem;
    }
    .data-left h4{
        font-size: 1.5rem;
    }
    .data-left p, .data-left a{
        font-size: 1rem ;
    }
    .btn-mulai{
        margin-top: 1rem;
        padding: 0.5rem 0.6rem !important;
    }
    
}
@media (min-width: 900px) {

}
</style>