<template>
    <div>
        <div v-if="lang === 'ind'">
            <!-- <Sidebar/> -->
            <nav class="navbar w-100 navbar-top navbar-expand-lg bg-black" id="nav">
            <div class="container">
                <div class="row navbar-flex w-100 pl-3">
                    <router-link class="navbar-brand" to="/">
                    <img src="../assets/img/ArtPay_samping_msaster.png" class="logo" />
                    <div class="logo_name">
                    </div>
                </router-link>
                <div class="pt-md-3">
                    <button class="btn-outline-secondary btn-nav btn nav-mobile" @click="openSidebar" >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ;msFilter:;"><path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path></svg>
                    </button>
                </div>
                <!-- <button class="navbar-toggler" type="button"  data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button> -->
                <div class="navbar-nav ms-auto nav-web">
                    <router-link to="/" class="nav-link link-nav px-3 px-xl-4 pt-3" :class="[datalink == '/' ? 'link-active' : '']">BERANDA</router-link>
                    <div class="dropdown pt-2 px-3 px-xl-4">
                        <button class="btn btn-transparant dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                            PRODUK
                        </button>
                        <div class="dropdown-menu">
                            <router-link class="dropdown-item" to="/pembayaran-online" :class="[datalink == '/pembayaran-online' ? 'link-active' : '']"><img src="../assets/img/payment-method1.png" style="width:20px; margin-right:10px;" alt="">Pembayaran Online</router-link>
                            <hr>
                            <router-link class="dropdown-item" to="/dompet-digital" :class="[datalink == '/dompet-digital' ? 'link-active' : '']"><img src="../assets/img/wallet1.png" style="width:20px;margin-right:10px;" alt="">Dompet Digital</router-link>
                        </div>
                    </div>
                    <router-link to="/biaya" class="nav-link px-3 px-xl-4 link-nav pt-3" :class="[datalink == '/biaya' ? 'link-active' : '']">BIAYA</router-link>
                    <router-link to="/hubungi-kami" class="nav-link px-3 px-xl-4 link-nav pt-3" :class="[datalink == '/hubungi-kami' ? 'link-active' : '']">HUBUNGI KAMI</router-link>
                    <div class="d-flex link-language px-1
                            pt-3 ">
                                <figure @click="setLang('ind')">
                                    <img src="../assets/img/indo_flag.png" alt="">
                                </figure>
                                <span>/</span>
                                <figure @click="setLang('eng')">
                                    <img src="../assets/img/usa_flag.png" alt="">
                                </figure>
                            </div>
                    <!-- <div class="pt-3 px-3 px-xl-4">
                        <router-link to="/login" class="btn btn-danger btn-nav-web btn-link-nav">MASUK / DAFTAR</router-link>
                    </div> -->
                </div>
                </div>
            </div>
        </nav>
        <div id="bottomsidebar" class="bottom-sidebar" @click="closeSidebar"></div>
        <div id="mySidebar" class="sidebar">
            <a href="javascript:void(0)" class="closebtn" @click="closeSidebar">&times;</a>
            <router-link to="/" >
                <img class="logo sidebar-image" src="../assets/img/ArtPay_samping_msaster.png" alt="">
            </router-link>
            <div class="pt-4">
                <router-link to="/" :class="[datalink == '/' ? 'link-active' : '']">BERANDA</router-link>
                <div id="accordion">
                    <div class="card bg-card-sidebar">
                        <div class="" id="headingOne">
                        <h5 class="mb-0">
                            <button class="btn btn-sidebar-produk btn-link w-100" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            PRODUK
                            <!-- <span class="when-open"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: #fff;transform: ;msFilter:;"><path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"></path></svg></span><span class="when-closed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: #fff;transform: ;msFilter:;"><path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path></svg></span> -->
                            </button>
                        </h5>
                        </div>

                        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                        <div class="card-body">
                            <router-link to="/pembayaran-online" :class="[datalink == '/pembayaran-online' ? 'link-active' : '']" class="dropdown-item"><img src="../assets/img/payment-method1.png" style="width:20px; margin-right:10px;" alt="">Pembayaran Online</router-link>
                            <router-link class="dropdown-item" :class="[datalink == '/dompet-digital' ? 'link-active' : '']" to="/dompet-digital"><img src="../assets/img/wallet1.png" style="width:20px;margin-right:10px;" alt="">Dompet Digital</router-link>
                        </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="dropdown">
                    <button class="btn btn-transparant dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                        PRODUK
                    </button>
                    <div class="dropdown-menu">
                        <router-link to="/pembayaran-online" :class="[datalink == '/pembayaran-online' ? 'link-active' : '']" class="dropdown-item"><img src="../assets/img/payment-method1.png" style="width:20px; margin-right:10px;" alt="">Pembayaran Online</router-link>
                        <hr>
                        <router-link class="dropdown-item" :class="[datalink == '/dompet-digital' ? 'link-active' : '']" to="/dompet-digital"><img src="../assets/img/wallet1.png" style="width:20px;margin-right:10px;" alt="">Dompet Digital</router-link>
                    </div>
                </div> -->
                <router-link to="/biaya" :class="[datalink == '/biaya' ? 'link-active' : '']">BIAYA</router-link>
                <router-link to="/hubungi-kami" :class="[datalink == '/hubungi-kami' ? 'link-active' : '']">HUBUNGI KAMI</router-link>
                <div class="d-flex link-language px-4
                            pt-3">
                                <figure @click="setLang('ind')">
                                    <img src="../assets/img/indo_flag.png" alt="">
                                </figure>
                                <span>/</span>
                                <figure @click="setLang('eng')">
                                    <img src="../assets/img/usa_flag.png" alt="">
                                </figure>
                            </div>
                <!-- <div class="p-4">
                    <router-link to="/login" class="btn btn-danger btn-link-nav">MASUK / DAFTAR</router-link>
                </div> -->
            </div>
        </div>
        </div>
        <div v-else-if="lang === 'eng'">
            <!-- <Sidebar/> -->
            <nav class="navbar w-100 navbar-top navbar-expand-lg bg-black" id="nav">
            <div class="container">
                <div class="row navbar-flex w-100 pl-3">
                    <router-link class="navbar-brand" to="/">
                    <img src="../assets/img/ArtPay_samping_msaster.png" class="logo" />
                    <div class="logo_name">
                    </div>
                </router-link>
                <div class="pt-md-3">
                    <button class="btn-outline-secondary btn-nav btn nav-mobile" @click="openSidebar" >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: ;msFilter:;"><path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path></svg>
                    </button>
                </div>
                <!-- <button class="navbar-toggler" type="button"  data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button> -->
                <div class="navbar-nav ms-auto nav-web">
                    <router-link to="/" class="nav-link link-nav px-3 px-xl-4 pt-3" :class="[datalink == '/' ? 'link-active' : '']">HOME</router-link>
                    <div class="dropdown pt-2 px-3 px-xl-4">
                        <button class="btn btn-transparant dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                            PRODUCTS
                        </button>
                        <div class="dropdown-menu">
                            <router-link class="dropdown-item" to="/pembayaran-online" :class="[datalink == '/pembayaran-online' ? 'link-active' : '']"><img src="../assets/img/payment-method1.png" style="width:20px; margin-right:10px;" alt="">Online Payments</router-link>
                            <hr>
                            <router-link class="dropdown-item" to="/dompet-digital" :class="[datalink == '/dompet-digital' ? 'link-active' : '']"><img src="../assets/img/wallet1.png" style="width:20px;margin-right:10px;" alt="">Digital Wallet</router-link>
                        </div>
                    </div>
                    <router-link to="/biaya" class="nav-link px-3 px-xl-4 link-nav pt-3" :class="[datalink == '/biaya' ? 'link-active' : '']">PRICING</router-link>
                    <router-link to="/hubungi-kami" class="nav-link px-3 px-xl-4 link-nav pt-3" :class="[datalink == '/hubungi-kami' ? 'link-active' : '']">CONTACT US</router-link>
                    <div class="d-flex link-language px-1
                            pt-3 ">
                                <figure @click="setLang('ind')">
                                    <img src="../assets/img/indo_flag.png" alt="">
                                </figure>
                                <span>/</span>
                                <figure @click="setLang('eng')">
                                    <img src="../assets/img/usa_flag.png" alt="">
                                </figure>
                            </div>
                    <!-- <div class="pt-3 px-3 px-xl-4">
                        <router-link to="/login" class="btn btn-danger btn-nav-web btn-link-nav">MASUK / DAFTAR</router-link>
                    </div> -->
                </div>
                </div>
            </div>
        </nav>
        <div id="bottomsidebar" class="bottom-sidebar" @click="closeSidebar"></div>
        <div id="mySidebar" class="sidebar">
            <a href="javascript:void(0)" class="closebtn" @click="closeSidebar">&times;</a>
            <router-link to="/" >
                <img class="logo sidebar-image" src="../assets/img/ArtPay_samping_msaster.png" alt="">
            </router-link>
            <div class="pt-4">
                <router-link to="/" :class="[datalink == '/' ? 'link-active' : '']">HOME</router-link>
                <div id="accordion">
                    <div class="card bg-card-sidebar">
                        <div class="" id="headingOne">
                        <h5 class="mb-0">
                            <button class="btn btn-sidebar-produk btn-link w-100" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            PRODUCTS
                            <!-- <span class="when-open"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: #fff;transform: ;msFilter:;"><path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"></path></svg></span><span class="when-closed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: #fff;transform: ;msFilter:;"><path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path></svg></span> -->
                            </button>
                        </h5>
                        </div>

                        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                        <div class="card-body">
                            <router-link to="/pembayaran-online" :class="[datalink == '/pembayaran-online' ? 'link-active' : '']" class="dropdown-item"><img src="../assets/img/payment-method1.png" style="width:20px; margin-right:10px;" alt="">Online Payments</router-link>
                            <router-link class="dropdown-item" :class="[datalink == '/dompet-digital' ? 'link-active' : '']" to="/dompet-digital"><img src="../assets/img/wallet1.png" style="width:20px;margin-right:10px;" alt="">Digital Wallet</router-link>
                        </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="dropdown">
                    <button class="btn btn-transparant dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                        PRODUK
                    </button>
                    <div class="dropdown-menu">
                        <router-link to="/pembayaran-online" :class="[datalink == '/pembayaran-online' ? 'link-active' : '']" class="dropdown-item"><img src="../assets/img/payment-method1.png" style="width:20px; margin-right:10px;" alt="">Pembayaran Online</router-link>
                        <hr>
                        <router-link class="dropdown-item" :class="[datalink == '/dompet-digital' ? 'link-active' : '']" to="/dompet-digital"><img src="../assets/img/wallet1.png" style="width:20px;margin-right:10px;" alt="">Dompet Digital</router-link>
                    </div>
                </div> -->
                <router-link to="/biaya" :class="[datalink == '/biaya' ? 'link-active' : '']">PRICING</router-link>
                <router-link to="/hubungi-kami" :class="[datalink == '/hubungi-kami' ? 'link-active' : '']">CONTACT US</router-link>
                <div class="d-flex link-language px-4
                            pt-3">
                                <figure @click="setLang('ind')">
                                    <img src="../assets/img/indo_flag.png" alt="">
                                </figure>
                                <span>/</span>
                                <figure @click="setLang('eng')">
                                    <img src="../assets/img/usa_flag.png" alt="">
                                </figure>
                            </div>
                <!-- <div class="p-4">
                    <router-link to="/login" class="btn btn-danger btn-link-nav">MASUK / DAFTAR</router-link>
                </div> -->
            </div>
        </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            datalink:''
        }
    },
    mounted(){
        this.checklink()
    },
    watch:{
        '$route.query': {
        handler: function() {
        document.getElementById("app").style.height = "auto";
        document.getElementById("app").style.overflowY = "auto";
        window.scrollTo(0,0);
            this.checklink()
        },
        deep: true,
        immediate: true,
        }
        },
    computed:{
        lang: function() {
            return this.$store.state.language;
        },
    },
  methods:{
    setLang(data){
        console.log(data);
        this.$store.dispatch('setlanguage',{
                        datalanguage:data
                })
    },
    checklink(){
        this.datalink = this.$router.currentRoute._value.path
    },
    openSidebar() {
      document.getElementById("mySidebar").style.width = "250px";
      document.getElementById("bottomsidebar").style.width = "100%";
      document.getElementById("app").style.height = "100vh";
      document.getElementById("app").style.overflowY = "hidden";
    },
    closeSidebar(){
      document.getElementById("mySidebar").style.width = "0";
      document.getElementById("bottomsidebar").style.width = "0";
      document.getElementById("app").style.height = "auto";
      document.getElementById("app").style.overflowY = "auto";
  }

  }
  
}
</script>
<style scoped>
.link-language{
    min-width: 2rem !important;
}
.link-language figure{
    width: 35px;
    cursor: pointer;
}
.link-language img{
    width: 100%;
}
#headingOne .btn-sidebar-produk.collapsed:after {
  content: "";
  background-image: url('../assets/img/downarrow.png');
  background-size: cover;
  width: 25px;
  height: 25px;
}
#headingOne .btn-sidebar-produk:after {
  content: "";
  background-image: url('../assets/img/rightarrow.png');
  background-size: cover;
  width: 25px;
  height: 25px;
  font-weight: 900;
  float: right;
}
.link-active{
    color: red !important;
    font-weight: 600;
}
.bg-card-sidebar{
    background-color: transparent !important;
}
.btn-sidebar-produk{
    padding: 0.5rem 1.75rem !important;
    text-align: left !important;
    color: #fff !important;
    text-decoration: none;
}
.bg-card-sidebar .card-body{
    padding: 0px !important;
}
.btn-nav{
    padding: 0.2rem 0.45rem !important;
    margin-top: 0.5rem !important;
}
.navbar-toggler-icon{
    width: 1rem !important;
    height: 1rem !important;
}
.navbar-top{
    position: fixed !important;
    top: 0 !important;
    z-index: 900;
}
.btn-link-nav{
    line-height: 2 !important;
}
.link-nav{
    line-height: 1.9;
    color: #fff ;
}
.link-nav:hover{
    color: rgba(255, 255, 255, 0.75) ;
}
.bg-black{
    background-color: #000!important;
}
.bg-black svg{
    fill: rgba(255, 255, 255, 1);
    width: 18px;
    height: 18px;
}
.navbar-brand img{
    width: 160px;
}
@media(max-width:500px){
    .navbar-brand img{
        width: 100px;
    }
}
.btn-transparant{
    background-color: transparent;
    border: 0px !important;
    line-height: 2 !important;
    color: rgba(255, 255, 255, 1) !important;
}
.btn-transparant:hover{
    color: rgba(255, 255, 255, 0.75);
}
.navbar-flex{
    justify-content: space-between;
    flex-wrap: inherit !important;
}
.nav-mobile{
    transition-delay: 5s;
    display: block;
}
.sidebar {
    position: fixed;
    height: 100vh;
    width: 0;
    z-index: 999;
    top: 0;
    left: 0;
    background-color: #000;
    overflow-x: hidden;
    padding-top: 15px;
    transition: 0.5s;
}
.bottom-sidebar{
  height: 100vh;
  position: fixed;
  z-index: 997;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  overflow-x: hidden;
  padding-top: 60px;
  transition: 0.5s;
}
.sidebar a{
font-size: 0.9rem;
}
.sidebar .btn-danger{
padding: 8px !important;
display: inline;
}
.sidebar button{
font-size: 0.8rem;
}
.sidebar a, .sidebar .dropdown button {
    padding: 0.5rem 8px 0.5rem 32px;
    text-decoration: none;
    
    color: #fff;
    display: block;
    transition: 0.3s;
}
.sidebar .dropdown .dropdown-menu{
    background-color: #000 !important;
}
.sidebar .dropdown .dropdown-menu .dropdown-item:hover{
    background-color: #181818 !important;
}
.dropdown-menu hr{
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}
.sidebar-image{
    width: 100px;
}
.sidebar a:hover {
    color: #f1f1f1;
}

.sidebar .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

.openbtn {
    font-size: 1rem;
    cursor: pointer;
    background-color: #2b9348;
    color: white;
    padding: 10px 15px;
    border: none;
}

.openbtn:hover {
    background-color: #2b9348;
}

#main {
    transition: margin-left 0.5s;
    padding: 20px;
}

@media screen and (max-height: 450px) {
    .sidebar {
        padding-top: 15px;
    }

    .sidebar a {
        font-size: 18px;
    }
}

@media (max-width: 993px) {
    .nav-web{
    transition-delay: 5s;
    display: none !important;
}
}
@media (min-width: 319px) {
}


@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
}

@media (min-width: 994px) {
    .nav-mobile{
    transition-delay: 5s;
        display: none !important;
    }
    .nav-web{
    transition-delay: 5s;
        display: flex;
        justify-content: space-between;
    }
    .btn-nav-web{
        font-size: 0.8rem !important;
        padding: 0.25rem 0.6rem !important;
    }
    
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>