<template>
    <section class="bg-black">
        
        <div class="container section-header pb-md-5">
          <nav aria-label="breadcrumb" v-if="lang === 'ind'">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/">Beranda</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">Pembayaran Online</li>
                </ol>
            </nav>
          <nav aria-label="breadcrumb" v-else-if="lang === 'eng'">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">Online Payment</li>
                </ol>
            </nav>
            <div class="row ps-md-5 pt-md-4 pe-md-5">
                <div class="col-7 col-md-5 ps-md-5 text-white text-md-start mt-md-4" data-aos="fade-right" data-aos-delay="400">
                    <div class="mt-lg-5 pt-md-5 data-left" v-if="lang === 'ind'">
                        <h4 class=""><b>Tampilkan Beragam Pilihan Metode Pembayaran di Situs Bisnis Anda Melalui API Sederhana</b></h4>
                        <p class=" lh-base"> Dapatkan metode pembayaran yang lengkap untuk situs bisnis Anda hanya dengan sekali integrasi.</p>
                        <router-link to="/hubungi-kami" class="btn btn-mulai btn-danger p-md-3 ps-5 pe-5 mb-4">
                            MULAI SEKARANG
                        </router-link>
                    </div>
                    <div class="mt-lg-5 pt-md-5 data-left" v-else-if="lang === 'eng'">
                        <h4 class=""><b>Display a Variety of Payment Methods on Your Business Website Through a Simple API Integration.</b></h4>
                        <p class=" lh-base"> Get comprehensive payment methods for your business website with just one
integration.</p>
                        <router-link to="/hubungi-kami" class="btn btn-mulai btn-danger p-md-3 ps-5 pe-5 mb-4">
                            START NOW
                        </router-link>
                    </div>
                </div>
                <div class="col-5 col-md-7 pb-md-5" data-aos="fade-left" data-aos-delay="400">
                    <img src="../../assets/img/8609189_5850.svg" class="w-100" alt="">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    computed:{
        lang: function() {
            return this.$store.state.language;
        },
    },
}
</script>
<style scoped>
.bg-black{
    background-color: #000 !important;
}
.breadcrumb{
    background-color: transparent !important;
    justify-content: end;
    font-size: 10px;
}
.breadcrumb a{
    color: #fff !important;
    font-weight: 700;
    text-decoration: none !important;
}
.breadcrumb .active{
    font-weight: 600;
}
.data-left h4{
    font-size: 0.75rem ;
}
.data-left p,.data-left a{
    font-size: 0.5rem ;
}
.section-header{
    padding-top: 55px;
    overflow-x: hidden
}
@media (min-width: 350px) {
    .data-left h4{
        font-size: 0.9rem;
    }
    .data-left p, .data-left a{
        font-size: 0.7rem;
    }
}
@media (min-width: 400px) {
    .data-left h4{
        font-size: 1rem;
    }
    .data-left p, .data-left a{
        font-size: 0.8rem ;
    }
}
@media (min-width: 700px) {
    .data-left h4{
        font-size: 1.75rem;
    }
    .data-left p, .data-left a{
        font-size: 1rem ;
    }
    .section-header{
        padding-top: 100px;
        overflow-x: hidden
    }
    .breadcrumb{
        font-size: 1rem;
    }
    .btn-mulai{
        margin-top: 1rem;
        padding: 0.5rem 0.6rem !important;
    }
}

</style>