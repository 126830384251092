<template>
    <div class="datasection pt-5">
        <h3 class="text-center" v-if="lang === 'ind'"><b>Apapun transaksinya, <span class="bold">Art<span class="text-danger">P</span>ay</span> solusinya</b></h3>
        <h3 class="text-center" v-else-if="lang === 'eng'"><b>No matter the transaction, <span class="bold">Art<span class="text-danger">P</span>ay</span> has the solution.</b></h3>
        <p class="text-center text-danger" v-if="lang === 'ind'"><b>Setiap transaksi jadi mudah dengan Artpay</b></p>
        <p class="text-center text-danger" v-else-if="lang === 'eng'"><b>Every transaction becomes easy with ArtPay</b></p>
        <div class="container mt-5">
            <div class="row">
                <div class="col-md-6 col-lg-6 pt-lg-5 data-artpay">
                    <ul class="ul-section mt-lg-4" v-if="lang === 'ind'">
                        <li>Integrasikan halaman pembayaran Artpay ke website bisnis Anda dengan sederhana dan cepat.</li>
                        <li>Kemudahan bagi pelanggan dengan memberikan beragam metode pembayaran.</li>
                        <li>Tingkatkan transaksi sukses dengan kenyamanan pembayaran.</li>
                    </ul>
                    <ul class="ul-section mt-lg-4" v-else-if="lang === 'eng'">
                        <li>Easily and swiftly integrate the ArtPay payment page into your business
website.</li>
                        <li>Provide convenience to your customers by offering a range of payment
methods.</li>
                        <li>Increase successful transactions with payment convenience.</li>
                    </ul>
                </div>
                <div class="col-md-6 col-lg-6 databank">
                    <div class="h-100">
                        <figure>
                            <img src="../../assets/img/dokuExample.png" alt="">
                        </figure>
                    </div>
                </div>
            </div>
        </div>
        <div class="container mt-5 pb-5">
            <div class="row" v-if="lang === 'ind'">
                <div class="col-lg-6 data-artpay">
                    <div class="h-100 p-2 p-md-4">
                        <h3><b>Pertanyaan <span class="text-danger">Umum</span></b></h3>
                        <p>Semua yang perlu Anda ketahui tentang Pembayaran Online.</p>
                    </div>
                </div>
                <div class="col-lg-6 databank">
                    <div class="h-100 p-2 p-md-4">
                        <div class="card p-5">
                            <h5><b>Bagaimana cara saya memulai integrasi dengan Pembayaran Online?</b></h5>
                            <p class="text-left">Lakukan proses pendaftaran akun pada Sandbox kami.</p>
                            <h5 class="mt-3"><b>Apakah terdapat notifikasi pada setiap pembayaran?</b></h5>
                            <p class="text-left">Merchant dapat memanfaatkan notifikasi HTTPS POST untuk memperbaharui status pembayaran, misalnya meneruskan transaksi ke pelanggan. Notifikasi melalui HTTPS POST akan dikirimkan ke server Merchant saat pelanggan menyelesaikan proses pembayaran.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-else-if="lang === 'eng'">
                <div class="col-lg-6 data-artpay">
                    <div class="h-100 p-2 p-md-4">
                        <h3><b>Frequently Asked  <span class="text-danger">Questions</span></b></h3>
                        <p>Everything you need to know about Online Payments.</p>
                    </div>
                </div>
                <div class="col-lg-6 databank">
                    <div class="h-100 p-2 p-md-4">
                        <div class="card p-5">
                            <h5><b>How do I start the integration with Online Payments?</b></h5>
                            <p class="text-left">Begin by registering an account in our Sandbox.</p>
                            <h5 class="mt-3"><b>Are there notifications for each payment?</b></h5>
                            <p class="text-left">Merchants can utilize HTTPS POST notifications to update payment statuses,
such as forwarding transactions to customers. HTTPS POST notifications will
be sent to the Merchant's server when customers complete the payment
process.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    computed:{
        lang: function() {
            return this.$store.state.language;
        },
    },
}
</script>
<style scoped>
.p-card-header{
    padding: 0 !important;
}
.text-left{
    text-align: left !important;
}
.datasection{
    background-image: url('../../assets/img/bgdata.png');
    background-repeat: repeat;
}
.data-artpay figure{
    width: 100px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10;
}
.data-artpay p{
    position: relative;
    z-index: 100;
}
.data-artpay figure img{
    width: 100%;
}
.listcard{
    background-color: #fff;
}
.databank figure{
    width: 100% !important;
}
.databank figure img{
    width: 100%;
}
.btn-accordion{
    text-align: left !important;
}
.card-header button{
    padding: 0.75rem 1.25rem !important;
    text-decoration: none !important;
    color: #fff !important;
    background-color: #000;
}

.ul-section li {
    position: relative;
    list-style-type: none;
}

.ul-section li:before {
    content: "";
    position: absolute;
    top: 3px;
    left: -25px;
    width: 15px;
    height: 15px;
    background-size: cover;
    background-image: url('../../assets/img/check.png');
}
@media (max-width: 500px) {
    .margin-top-data-artpay{
        margin-top: 2rem;
    }
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
.databank figure{
       width: 100%;
    }
}

@media (min-width: 1023px) {
    
    
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>