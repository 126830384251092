<template>
    <div class="datalogin">
        <div class="container h-100">
            <div class="row h-100">
                <div class="col-md-3 col-lg-6"></div>
                <div class="col-md-9 col-lg-6 pt-5 bg-white">
                    <div class="card mt-2 mt-md-5">
                        <div class="card-header">
                            <router-link to="/">
                                <img src="../../assets/img/ArtPay_samping_msaster.png" alt="">
                            </router-link>
                        </div>
                        <div class="card-body">
                            <h5><b>SELAMAT DATANG</b></h5>
                            <p>Silahkan masuk dengan akun yang anda miliki</p>
                            <form class="form-regis" ref="registrasi" @submit.prevent="login">
                                <div class="row">
                                    <div class="col-md mt-3">
                                        <label for="email">Email atau No Telepon</label>
                                        <input id="email" type="text" class="form-control radius" placeholder="Email atau No Telepon" v-model="email" :class="[v$.email.$error ? 'is-invalid' : '']">
                                        <span v-if="v$.email.$error" class="text-danger font-small pesan-error">{{v$.email.$errors[0].$message}}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md mt-3">
                                        <label for="password">Password</label>
                                        <input id="password" type="password" class="form-control radius" placeholder="Password" v-model="password" :class="[v$.password.$error ? 'is-invalid' : '']">
                                        <span v-if="v$.password.$error" class="text-danger font-small pesan-error">{{v$.password.$errors[0].$message}}</span>
                                    </div>
                                </div>
                                <div class="w-100 mt-3 lupa-pass">
                                    <router-link to="" class="text-danger">Lupa Password?</router-link>
                                </div>
                                <button class="btn btn-danger mt-3 w-100 py-2">Masuk</button>
                                <router-link to="/register" class="btn btn-outline-danger mt-3 py-2 w-100">Daftar</router-link>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img class="img-login" src="../../assets/img/login.svg" alt="">
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required,helpers, minLength  } from '@vuelidate/validators'
export default {
    data(){
        return{
            email:'',
            password:'',
        }
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations() {
        return {
            email: {
                required: helpers.withMessage("Email tidak boleh kosong", required)
            },
            password: {
                required: helpers.withMessage("Kata Sandi tidak boleh kosong", required), 
                min: helpers.withMessage(
                    ({
                        $params
                    }) => `Kata Sandi minimal memiliki ${$params.min} karakter `,
                    minLength(8)
                )
            }                           
            
        }
    },
    methods:{
         login() {

            this.v$.$validate()
            if ( !this.v$.$error) {
                console.log("tidak error");
            }
        },
    }
}
</script>
<style scoped>
.bg-white{
    z-index: 10;
}
.datalogin{
    width: 100%;
    height: 100vh;
    position: fixed;
    overflow: hidden;
}
.card{
    z-index: 20 !important;
}
.card-header{
    background-color: #000;
}
.card-header img{
    width: 100px;
}
.card-body p{
    margin-bottom: 0px !important;
}
.lupa-pass a{
    text-align: right;
    width: 100%;
    display: block;
}
.img-login{
    width: 80%;
    position: absolute;
    left: -30px;
    bottom: -30px;
    z-index: 1 !important;
}
@media (min-width: 319px) {
}

@media (max-width: 374px) {
}

@media (max-width: 424px) {
}
@media (max-width: 500px) {
}

@media (min-width: 767px) {
.img-login{
    width: 30%;
    position: absolute;
    left: -30px;
    bottom: 0vh;
    z-index: 1 !important;
}
}

@media (min-width: 1023px) {
    .img-login{
        width: 50%;
        position: absolute;
        left: -30px;
        bottom: -20vh;
        z-index: 1 !important;
    }
}

@media (min-width: 1239px) {
    
}

@media (min-width: 2000px) {
}
</style>