<template>
    <div class="datasection py-3 py-md-5">
        <img src="../../assets/img/contruction.svg" alt="">
        <h4 class="pt-2 w-100 text-center"><b>Comming Soon</b></h4>
    </div>
</template>
<style scoped>
.datasection{
    background-image: url('../../assets/img/bgdata.png');
    background-repeat: repeat;
}
.datasection img{
    width: 90%;
    display: block;
    margin: 0 auto;
}
@media (min-width: 319px) {
}

@media (min-width: 374px) {
}

@media (min-width: 424px) {
}

@media (min-width: 767px) {
    .datasection img{
    width: 75%;
    display: block;
    margin: 0 auto;
}
.datasection h4{
    font-size: 2.5rem !important;
}

}

@media (min-width: 1023px) {
    .datasection img{
        width: 30%;
        display: block;
        margin: 0 auto;
    }
    
    
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>