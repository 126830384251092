import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PembayaranView from '../views/PembayaranOnline.vue'
import DompetDigital from '../views/DompetDigital.vue'
import Biaya from '../views/BiayaArtpay.vue'
import HubungiKami from '../views/HubungiKami.vue'
import TentangKami from '../views/TentangKami.vue'
import KebijakanPrivasi from '../views/KebijakanPrivasi.vue'
import RegisterView from '../views/Auth/RegisterView.vue'
import LoginView from '../views/Auth/LoginView.vue'
import Profile from '../views/Dashboard/DashboardView.vue'
import KredensialView from '../components/Dashboard/KredensialView.vue'
import TransaksiView from '../components/Dashboard/TransaksiViews.vue'
import DetailTransaksiView from '../components/Dashboard/DetailTransaksi.vue'
import DetailPengaturan from '../components/Dashboard/PengaturanView.vue'
import Cobadata from '../components/CobaView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/coba',
    name: 'coba',
    component: Cobadata
  },
  {
    path: '/pembayaran-online',
    name: 'pembayaranonline',
    component: PembayaranView
  },
  {
    path: '/dompet-digital',
    name: 'Dompetdigital',
    component: DompetDigital
  },
  {
    path: '/biaya',
    name: 'biaya',
    component: Biaya
  },
  {
    path: '/hubungi-kami',
    name: 'hubungi-kami',
    component: HubungiKami
  },
  {
    path: '/tentang-kami',
    name: 'tentang-kami',
    component: TentangKami
  },
  {
    path: '/kebijakan-privasi',
    name: 'kebijakan-privasi',
    component: KebijakanPrivasi
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    // redirect: VueCookies.isKey('token') ? '/profile/dashboard' : '/login',
    // props: (route) => ({ param: route.params || 'dashboard' }) 
    children: [
        {
            path: '/profile/kredensial',
            component: KredensialView,
            name: 'Kredensial',
            // meta: {
            //     requiresAuth: true
            // }
        },
        {
            path: '/profile/transaksi',
            component: TransaksiView,
            name: 'Transaksi',
            // meta: {
            //     requiresAuth: true
            // }
        },
        {
            path: '/profile/detail-transaksi',
            component: DetailTransaksiView,
            name: 'DetailTransaksi',
            // meta: {
            //     requiresAuth: true
            // }
        },
        {
            path: '/profile/pengaturan',
            component: DetailPengaturan,
            name: 'DetailPengaturan',
            // meta: {
            //     requiresAuth: true
            // }
        },
      ]
    },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
