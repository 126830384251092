<template>
    <div class="dataregister pt-5">
        <div class="p-md-5 p-2">
            <div class="card">
                <div class="card-header d-header">
                    <router-link to="/"><img src="../../assets/img/ArtPay_samping_msaster.png" alt=""></router-link>
                    <div class="pt-md-2">
                        <h5><b>Bergabung Bersama ArtPay</b></h5>
                        <p>Transaparan, tanpa biaya instalasi & biaya bulanan</p>
                    </div>
                </div>
                <div class="card-body">
                    <form class="form-regis" ref="registrasi" @submit.prevent="registrasi">
                        <div class="row">
                            <div class="col-md mt-4">
                                <label for="name">Nama Lengkap</label>
                                <input id="name" type="text" class="form-control mt-1 radius" v-model="name" placeholder="Nama Lengkap" :class="[v$.name.$error ? 'is-invalid' : '']">
                                <span v-if="v$.name.$error" class="text-danger font-small pesan-error">{{v$.name.$errors[0].$message}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md mt-4">
                                <label for="name_usaha">Nama Usaha</label>
                                <input id="name_usaha" type="text" class="form-control mt-1 radius" placeholder="Nama Usaha" v-model="name_usaha" :class="[v$.name_usaha.$error ? 'is-invalid' : '']">
                                <span v-if="v$.name_usaha.$error" class="text-danger font-small pesan-error">{{v$.name_usaha.$errors[0].$message}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md mt-4">
                                <label for="email">Email</label>
                                <input id="email" type="email" class="form-control mt-1 radius" placeholder="Alamat Email" v-model="email" :class="[v$.email.$error ? 'is-invalid' : '']">
                                <span v-if="v$.email.$error" class="text-danger font-small pesan-error">{{v$.email.$errors[0].$message}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md mt-4">
                                <label for="Telepon">No Telepon</label>
                                <input id="Telepon" type="text" class="form-control mt-1 radius" placeholder="Telepon" v-model="telepon" :class="[v$.telepon.$error ? 'is-invalid' : '']">
                                <span v-if="v$.telepon.$error" class="text-danger font-small pesan-error">{{v$.telepon.$errors[0].$message}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md mt-4">
                                <label for="password">Password</label>
                                <input id="password" type="password" class="form-control mt-1 radius" placeholder="Password" v-model="password" :class="[v$.password.$error ? 'is-invalid' : '']">
                                <span v-if="v$.password.$error" class="text-danger font-small pesan-error">{{v$.password.$errors[0].$message}}</span>
                            </div>
                        </div>
                        <button class="btn btn-danger w-100 btn-kirim px-5 py-3 mt-3 mt-lg-5">Buat Akun</button>
                        <p class="text-center mt-3">Sudah memiliki akun? <router-link to="/login" class="text-danger"><b>Masuk</b></router-link></p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import useVuelidate from '@vuelidate/core'
import { required, email, helpers, minLength, numeric  } from '@vuelidate/validators'
export default {
    data(){
        return{
            name:"",
            name_usaha:'',
            email:'',
            telepon:'',
            password:'',
        }
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations() {
        return {
            name: {
                required: helpers.withMessage("Nama tidak boleh kosong", required),
            },
            name_usaha: {
                required: helpers.withMessage("Nama Usaha tidak boleh kosong", required),
            },
            email: {
                required: helpers.withMessage("Email tidak boleh kosong", required),
                email: helpers.withMessage("Format email salah", email)
            },
            password: {
                required: helpers.withMessage("Kata Sandi tidak boleh kosong", required), 
                min: helpers.withMessage(
                    ({
                        $params
                    }) => `Kata Sandi minimal memiliki ${$params.min} karakter `,
                    minLength(8)
                )
            },
            telepon: {
                required: helpers.withMessage("No telepon tidak boleh kosong", required),
                numeric: helpers.withMessage("Pastikan data yang diinput berupa angka", numeric)
            },
            
        }
    },
    methods:{
         registrasi() {

            this.v$.$validate()
            if ( !this.v$.$error) {
                console.log("tidak error");
            }
        },
    }
}
</script>
<style scoped>
.dataregister{
    background-image: url('../../assets/img/bgdata.png');
}

.card-header{
    background-color: #000;
    color: #fff;
}
.card-header img{
    height: 55px;
}
.d-header{
    display: block;
}

@media (min-width: 319px) {
}

@media (max-width: 374px) {
    .d-header h5{
        font-size: 0.9rem;
    }
    .d-header p{
        font-size: 0.7rem;
    }
}

@media (max-width: 424px) {
    .d-header h5{
        font-size: 1rem;
    }
    .d-header p{
        font-size: 0.8rem;
    }
}
@media (max-width: 500px) {
    .d-header h5{
        font-size: 1.1rem;
    }
    .d-header p{
        font-size: 1rem;
    }
}

@media (min-width: 767px) {
.d-header{
    display: flex;
}
.card{
    width: 650px;
    display: block;
    margin: 0 auto;
}
}

@media (min-width: 1023px) {
    
}

@media (min-width: 1439px) {
}

@media (min-width: 2000px) {
}
</style>